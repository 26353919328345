<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="update">
              <div class="row">

                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Customer <span class="text-danger">*</span></label>
                      <vue-feather type="plus-circle" class="text-primary" style="cursor: pointer;"
                        @click="goPageCallback('addcustomer', 'addpurchaseorder')"></vue-feather>
                    </div>
                    <model-select :options="data_customer" placeholder="Pilih customer"
                      v-model="this.currentData.customer_detail.id" style="z-index:1004">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Project Code</label>
                    <input type="text" v-model="currentData.code_project" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>PO Number </label>
                    <input type="text" v-model="currentData.po_number" placeholder="" />
                  </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group" style="margin-bottom: 0px!important; ">
                    <label>Purchase Order Date <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 1002;">
                      <datepicker class="picker" v-model="currentData.invoice_date" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group" style="margin-bottom: 0px!important; ">
                    <label>Due Date <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 1001;">
                      <datepicker class="picker" v-model="currentData.due_date" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>



                <!-- table item -->
                <div class="row  ">
                  <div class="col-12  mt-4 mb-2 d-flex gap-2 align-items-end justify-content-between">
                    <div class="">
                      Table Item
                    </div>
                    <vue-feather type="plus-circle" class="text-primary" style="cursor: pointer;"
                      @click="addItem"></vue-feather>

                  </div>

                </div>

                <div class="row pe-0">
                  <div class="col-12 pe-0">
                    <div class=" table-responsive mb-3">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Product Name</th>
                            <th>Description</th>
                            <th>Marketing</th>
                            <th>Qty</th>
                            <th>Price</th>

                            <!-- <th>Discount</th> -->
                            <th>Total</th>
                            <th>Action</th>

                          </tr>
                        </thead>
                        <tbody>

                          <tr v-for="(item, index) in currentData.invoice_detail" :key="item.id">
                            <td>
                              {{ index + 1 }}
                            </td>

                            <td>
                              {{ item.name }}
                            </td>
                            <td v-html="item.deskripsi">


                            </td>
                            <td>
                              {{ item.nama_marketing }}
                            </td>
                            <td>

                              {{ item.qty }}
                            </td>
                            <td>

                              {{ rupiah(item.rate) }}
                            </td>
                            <td>

                              {{ rupiah(item.item_total) }}
                            </td>
                            <td>
                              <a href="javascript:void(0);" class="me-2"
                                @click="editItem(item.id_items, item.id_marketing, item.qty, item.id)"> <img
                                  src="@/assets/img/icons/edit.svg" alt="img" /></a>
                              <a href="javascript:void(0);" class="text-danger me-2" @click="deleteItem(index)">
                                <vue-feather type="trash"></vue-feather></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="row ps-2 ms-1  pe-3 me-1 mb-4">
                  <div class="col-12 d-flex justify-content-between w-100 ps-0 pe-0" style="border:1px solid #f8f8f8">
                    <div class="w-100 " style="background-color: #fafbfe!important;">
                      <h4 class="order-total">Subtotal</h4>
                    </div>
                    <div class="w-100">
                      <h4 class="order-amount">{{ rupiah(totalAmount) }}</h4>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-between w-100 ps-0 pe-0" style="border:1px solid #f8f8f8">
                    <div class="w-100 " style="background-color: #fafbfe!important;">
                      <h4 class="order-total">Discount </h4>
                    </div>
                    <div class="w-100 d-flex justify-content-between  align-items-center">
                      <div class="d-flex gap-2">
                        <div class="form-group mb-0">
                          <select class="form-control form-white" data-placeholder="Pilih tipe"
                            v-model="currentData.discount_type">
                            <option value="rupiah">Rp.</option>
                            <option value="persen">%</option>
                          </select>
                        </div>
                        <div class="form-group mb-0">
                          <input type="number" v-model="currentData.calculated_discount" @focus="selectAll"
                            placeholder="" />
                        </div>
                      </div>

                      <div>
                        <h4 class="order-amount">{{ rupiah(currentData.discount_amount) }}</h4>
                      </div>

                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-between w-100 ps-0 pe-0" style="border:1px solid #f8f8f8">
                    <div class="w-100 " style="background-color: #fafbfe!important;">
                      <h4 class="order-total">Tax {{ currentData.tax_name }}</h4>
                    </div>
                    <div class="w-100 d-flex justify-content-between  align-items-center">
                      <div class="d-flex gap-2">
                        <div class="form-group mb-0">
                          <select class="form-control form-white" data-placeholder="Pilih tipe"
                            v-model="currentData.tax_type" disabled>
                            <option value="persen">%</option>
                            <option value="rupiah">Rp.</option>
                          </select>
                        </div>
                        <div class="form-group mb-0">
                          <input type="number" v-model="currentData.tax_rate" @focus="selectAll" placeholder="" />
                        </div>
                      </div>

                      <div>
                        <h4 class="order-amount">{{
          rupiah(currentData.tax_amount) }}</h4>
                      </div>

                    </div>

                  </div>
                  <div class="col-12 d-flex justify-content-between w-100 ps-0 pe-0" style="border:1px solid #f8f8f8">
                    <div class="w-100" style="background-color: #fafbfe!important;">
                      <h4 class="order-total">Grand Total</h4>
                    </div>
                    <div class="w-100 d-flex justify-content-end">
                      <div>
                        <h4 class="order-amount">{{ rupiah(currentData.grand_total) }}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Payment Term</label>
                      <textarea class="form-control" v-model="currentData.quotation_term">
                    </textarea>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Purchase Order Note</label>
                      <textarea class="form-control" v-model="currentData.quotation_note">
                    </textarea>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Save</button>
                  <router-link to="purchaseorders" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>

  <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#modaladditeminvoice"
    ref="buttonopenmodaladditeminvoice" hidden><i class="fa fa-plus me-2"></i></a>
  <additeminvoicemodal :marketingdata="data_marketing" :kategoridata="data_kategori" :itemdata="data_item_filter"
    @id_kategori_selected="handleKategoriSelected" @id_item_selected="handleItemSelected" :isMarketing="isMarketing"
    @item_object="handleItemAdd" :itemEdited="itemEdited">
  </additeminvoicemodal>


</template>
<style scoped>
.order-total {

  color: #637381;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-right: 1px solid #f8f8f8;
  background: #fafbfe;
}

.order-amount {
  color: #212b36;
  font-size: 14px;
  padding: 10px;
  font-weight: 700;
  text-align: right;

}

.form-group input[type="number"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiah, rupiahInput } from "@/assets/js/function";
// import modaladditem from "./modaladditem";
import { ModelSelect } from 'vue-search-select';

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect
  },
  name: "edit Invoice",
  data() {
    return {
      filter: true,
      title: "Add Purchase Order",
      title1: "add purchase order data",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentDate: new Date,
      currentData: {
        customer_detail: {
          id: "",
        },
        invoice_detail: [],
        invoice_payment: [],
        discount_type: "rupiah",
        calculated_discount: "0",
        discount_amount: 0,
        tax_amount: 0,
        tax_rate: 0,
        tax_name: "PPN",
        tax_type: "persen",
        grand_total: 0,
        bank_name: "",
        po_number: "",
        code_project: "",

      },

      data_customer: [],
      data_item: [],
      data_kategori: [],
      data_marketing: [],
      data_payments: [],
      data_bank: [],


      //to modaladditem
      data_item_filter: [],
      isMarketing: false,
      itemEdited: {
        marketing_id: "",
        kategori_id: "",
        item_id: "",

      },
      paymentEdited: {
        id: "",
        payment_method: "",
        payment_amount: "",
        payment_notes: "",
        payment_date: new Date(),
      },



      // cek deposit
      status_item_deposit: false,

      //cek payment
      payment_active: "",

      //test 
      batas_payment_edit: 0,

      //rate 
      rate_active: "",

      pass_data: {
        from: "",
      },

      //bank
      bankCek: false,

      bank_selected: "",




    };
  },
  computed: {
    totalAmount() {

      if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
        return 0;
      } else {
        const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.item_total), 0)
        return total
      }
    },
    total_payment() {
      if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
        return 0;
      } else {
        const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.payment_amount), 0)
        return total
      }
    },

    batas_payment() {
      let payment = 0;
      let amount = 0;
      if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
        payment = 0;
      } else {
        const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.payment_amount), 0)
        payment = total;
      }
      if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
        amount = 0;
      } else {
        const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.item_total), 0)
        amount = total
      }


      return amount - payment;
    }

  },
  watch: {
    'bank_selected': {
      handler(newValue) {
        this.bankCek = false;
      }
    },
    'currentData.customer_detail.id': {
      handler(newValue) {
        this.currentData.invoice_detail = [];
        this.currentData.invoice_payment = [];

        this.cek_rate_active();

      }
    },
    'currentData.discount_type': {
      handler(newValue) {
        if (this.currentData.calculated_discount == '' || (this.currentData.calculated_discount > 100 && newValue == 'persen')
          || (this.currentData.calculated_discount < 0) || (newValue == 'rupiah' && this.currentData.calculated_discount > this.totalAmount)) {
          this.currentData.calculated_discount = 0;
        }
        if (newValue == 'rupiah') {
          this.currentData.discount_amount = this.currentData.calculated_discount;
        } else if (newValue == 'persen' && this.totalAmount > 0) {
          this.currentData.discount_amount = parseInt(this.currentData.calculated_discount) * (parseInt(this.totalAmount)) / 100;
        } else {
          this.currentData.discount_amount = 0;
        }

        this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
      }
    },
    'currentData.calculated_discount': {
      handler(newValue) {
        if (this.currentData.calculated_discount == '' || (newValue > 100 && this.currentData.discount_type == 'persen')
          || newValue < 0 || (this.currentData.discount_type == 'rupiah' && newValue > this.totalAmount)) {
          this.currentData.calculated_discount = 0;
        }
        if (this.currentData.discount_type == 'rupiah') {
          this.currentData.discount_amount = newValue;
        } else if (this.currentData.discount_type == 'persen' && this.totalAmount > 0) {
          this.currentData.discount_amount = parseInt(newValue) * (parseInt(this.totalAmount)) / 100;
        } else {
          this.currentData.discount_amount = 0;
        }


        this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
      }
    },
    'currentData.tax_rate': {
      handler(newValue) {
        if ((newValue > 100 && this.currentData.tax_type == 'persen') || newValue < 0) {
          this.currentData.tax_rate = 0;
        }

        if (this.currentData.tax_type == 'persen' && this.totalAmount > 0) {
          this.currentData.tax_amount = parseInt(newValue) * (parseInt(this.totalAmount)) / 100;
        } else {
          this.currentData.tax_amount = 0;
        }
        this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
      }
    }
  },

  created() {
    ShowLoading();
    const token = Cookies.get("token_refresh");

    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    // get needed data
    this.getData();
    this.currentData.invoice_date = new Date()
    this.currentData.payment_date = new Date()
    this.currentData.due_date = new Date()
    CloseLoading();


  },

  methods: {


    rupiah,
    selectAll(event) {
      // Select all text when input field is focused
      event.target.select();
    },
    goPageCallback(url, callback) {
      localStorage.setItem('callback', callback);
      goPage(url);

    },
    cek_deposit() {
      const index = this.currentData.invoice_detail.findIndex((record) => record.isdeposit == 'AKTIF');
      const indexPayment = this.data_payments.findIndex((record) => record.nama == 'DEPOSIT');

      const indexCustomer = this.data_customer.findIndex((record) => record.id == this.currentData.customer_detail.id);
      if (index > -1) {
        this.status_item_deposit = true;
      } else {
        this.status_item_deposit = false;
      }

      if ((indexPayment > -1 && index > -1) || (indexCustomer > -1 && this.data_customer[indexCustomer].amount_deposit <= 0)) {
        this.data_payments.splice(indexPayment, 1);
        if (this.currentData.payment_method == 'DEPOSIT') {
          this.currentData.payment_method = "";
        }
      } else if (indexCustomer > -1 && this.data_customer[indexCustomer].amount_deposit > 0 && indexPayment < 0) {
        this.data_payments.push({ text: 'DEPOSIT (' + rupiah(this.data_customer[indexCustomer].amount_deposit) + ')', value: this.data_payments.length, nama: 'DEPOSIT' })
      }

    },
    handleKategoriSelected(item) {
      this.data_item_filter = this.dataItemsFilterKategori(item);
    },

    dataItemsFilterKategori(id) {
      return this.data_item.filter(item => {
        // Apply your filtering condition
        if (item.id_kategori.toLowerCase().includes(id)) {
          // Modify the 'value' attribute here
          item.text = item.nama + ' (' + rupiah(item[this.rate_active]) + ') ';// Your modification logic here;
          return true; // Keep this item in the filtered result
        }
        return false; // Exclude this item from the filtered result
      });
    },
    handleItemSelected(item) {
      this.isMarketing = item;
    },
    handleItemAdd(item) {

      const index = this.data_item.findIndex((record) => record.id == item.id_items);
      const indexMarketing = this.data_marketing.findIndex((record) => record.id == item.id_marketing);

      const indexKeranjangUpdate = this.currentData.invoice_detail.findIndex(
        (record) => record.id == item.id
      );


      const rate_active = this.cek_rate_active();
      let nama_marketing = "";
      if (this.data_item[index].ismarketing == 'AKTIF' && this.data_marketing[indexMarketing] != undefined) {
        nama_marketing = this.data_marketing[indexMarketing].fullname;
      }


      const itemPrepare = {
        ...item, name: this.data_item[index].nama,
        isdeposit: this.data_item[index].isdeposit,
        deskripsi: this.data_item[index].deskripsi,
        nama_marketing: nama_marketing,
        rate: this.data_item[index][rate_active],
        item_total: item.qty * parseInt(this.data_item[index][rate_active]),
      };

      this.cek_deposit();

      if (item.status == 'tambah') {
        itemPrepare.id = this.currentData.invoice_detail.length;


        if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK') {
          this.currentData.invoice_detail.push(itemPrepare);
        } else if (this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF') {
          this.currentData.invoice_detail.push(itemPrepare);
        } else if (this.status_item_deposit == false && this.currentData.invoice_detail.length == 0 && this.data_item[index].isdeposit == 'AKTIF') {
          this.currentData.invoice_detail.push(itemPrepare);
        }
        else {
          AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
        }
      } else {
        if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK' && indexKeranjangUpdate > -1) {
          this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;
        } else if ((this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1) || ((this.status_item_deposit == false && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1 && this.currentData.invoice_detail.length == 1))) {
          if (this.data_item[index].isdeposit == 'AKTIF' && this.currentData.invoice_detail.length == 1) {
            this.currentData.invoice_payment = [];
          }
          this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;

        } else if (indexKeranjangUpdate == -1) {
          AlertPopup('error', '', 'item tidak ditemukan', 2000, true);
        }
        else {
          AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
        }
      }
      this.cek_deposit();

      this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;

    },
    cek_rate_active() {
      let status = "rate";
      if (this.currentData.customer_detail.id == "0" || this.currentData.customer_detail.id == 0) {
        status = 'rate';
      } else {
        const indexCustomer = this.data_customer.findIndex((record) => record.id === this.currentData.customer_detail.id);
        if (this.data_customer[indexCustomer].rate == "0") {
          status = "rate";
        } else {
          status = 'rate' + this.data_customer[indexCustomer].rate;
        }
      }
      this.rate_active = status;
      return status;
    },
    deleteItem(indexToRemove) {
      this.currentData.invoice_detail.splice(indexToRemove, 1);
      this.cek_deposit();
      if (this.currentData.invoice_detail.length == 0) {
        this.currentData.invoice_payment = [];
      }
      this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
    },

    getData() {

      // this.data_customer.push({ id: 0, text: 'Walk in', rate: "0", value: "0" });

      const customer = JSON.parse(localStorage.getItem('customer'));
      customer.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.fullname };
        this.data_customer.push(item_prepare)
      });


      //get data kategori 
      const kategori = JSON.parse(localStorage.getItem('kategori'));
      kategori.forEach((item, index) => {

        let item_prepare = { ...item, value: item.id, text: item.nama };
        this.data_kategori.push(item_prepare)
      });

      //data payment
      const payment = JSON.parse(localStorage.getItem('payments'));
      payment.forEach((item, index) => {
        let item_prepare = { value: index, text: item.nama.toUpperCase(), nama: item.nama.toUpperCase() };
        this.data_payments.push(item_prepare)
      });

      const marketing = JSON.parse(localStorage.getItem('marketing'));
      marketing.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.fullname };
        this.data_marketing.push(item_prepare)
      });

      const item = JSON.parse(localStorage.getItem('item'));
      item.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.nama, };
        this.data_item.push(item_prepare)
      });

      const bank = JSON.parse(localStorage.getItem('banks'));
      bank.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.nama + ' ( No Rek: ' + item.nomor_rekening + ' / ' + item.nama_account + ' )', };
        this.data_bank.push(item_prepare);
      });

      if (localStorage.getItem('pass_data') != null) {
        this.pass_data = JSON.parse(localStorage.getItem('pass_data'));

        if (this.pass_data.from == 'customer') {
          const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
          this.data_customer.unshift(item_prepare);

        } else if (this.pass_data.from == 'marketing') {
          const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
          this.data_marketing.unshift(item_prepare);

        }

      }

      if (localStorage.getItem('pass_data') != null) {
        if (this.pass_data.from == 'marketing') {
          localStorage.setItem('marketing', JSON.stringify(this.data_marketing));
        } else if (this.pass_data.from == 'customer') {
          localStorage.setItem('customer', JSON.stringify(this.data_customer));
        }
        localStorage.removeItem('pass_data');
      }




    },
    addItem() {
      if (this.currentData.customer_detail.id == '') {
        AlertPopup('error', '', 'Please select customer first ', 2000, true);

        return true;
      }

      this.itemEdited = {
        marketing_id: "",
        kategori_id: "",
        item_id: "",
        qty: 1,
        status: 'tambah'
      }

      const openButton = this.$refs.buttonopenmodaladditeminvoice;
      openButton.click();

    },
    editItem(item_id, marketing_id, qty, id) {
      const index = this.data_item.findIndex((record) => record.id == item_id);
      const indexKategori = this.data_kategori.findIndex((record) => record.id == this.data_item[index].id_kategori);

      this.itemEdited = {
        marketing_id: marketing_id,
        kategori_id: this.data_kategori[indexKategori].id,
        item_id: item_id,
        qty: qty,
        status: 'edit',
        id: id,
      }
      const openButton = this.$refs.buttonopenmodaladditeminvoice;
      openButton.click();
    },



    //payment
    handlePaymentAdd(item) {

      const indexKeranjangUpdate = this.currentData.invoice_payment.findIndex(
        (record) => record.id == item.id
      );
      item.payment_method = this.data_payments[item.payment_method].nama;

      if (item.status == 'tambah') {
        item.id = this.currentData.invoice_payment.length;

        this.currentData.invoice_payment.push(item);
      } else {
        this.currentData.invoice_payment[indexKeranjangUpdate] = item;
      }
    },


    addPayment() {
      const paymentobject = {
        id: "",
        payment_method: "",
        payment_amount: rupiahInput(0),
        payment_notes: "",
        payment_date: new Date(),
        status: 'tambah'
      }
      this.paymentEdited = paymentobject;
      const openButton = this.$refs.buttonopenmodalpayment;
      openButton.click();


    },
    editPayment(id, payment_amount, payment_method, payment_date, payment_notes) {

      const indexPayment = this.data_payments.findIndex((list) => list.nama == payment_method);
      const paymentobject = {
        id: id,
        payment_method: indexPayment,
        payment_amount: rupiahInput(parseInt(payment_amount)),
        payment_notes: payment_notes,
        payment_date: new Date(payment_date),
        status: 'update'
      }
      // total amount 
      let amount = 0;
      if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
        amount = 0;
      } else {
        const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.item_total), 0)
        amount = total;
      }

      let payment = 0;
      if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
        payment = 0;
      } else {
        const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.payment_amount), 0)
        payment = total
      }

      this.batas_payment_edit = parseInt(amount) - parseInt(payment) + parseInt(payment_amount);

      this.paymentEdited = paymentobject;
      const openButton = this.$refs.buttonopenmodalpayment;
      openButton.click();
    },
    deletePayment(index) {
      this.currentData.invoice_payment.splice(index, 1);



    },

    convertDate(value) {
      const date = new Date(value);

      // Use built-in methods to get year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    update() {
      if (this.currentData.invoice_detail.length == 0) {
        AlertPopup("error", "", 'Please select item for sale', 1500, false);
        return true;
      }



      ShowLoading();
      let formData = new FormData();
      const currentDate = new Date().toISOString().split('T')[0];

      const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
        parseInt(currentValue.item_total), 0);
      let item_id = [];
      let item_qty = [];
      let item_rate = [];
      let item_discount_type = [];
      let item_discount_amount = [];
      let item_is_tax = [];
      let item_name = [];
      let item_calculated_discount = [];
      let item_total = [];
      let item_isdeposit = [];
      let item_id_marketing = [];
      let payment_amount = [0];
      let payment_method = [""];
      let payment_date = [""];
      let payment_notes = [""];


      this.currentData.invoice_detail.forEach((item) => {
        item_id.push(item.id_items)
        item_qty.push(item.qty)

        // const indexItem = this.data_items.findIndex((list) => list.id === item.id_items);
        item_rate.push(item.rate);
        item_discount_type.push(0);
        item_discount_amount.push(0);
        item_is_tax.push(0);
        item_name.push(item.name);
        item_calculated_discount.push(0);
        item_total.push(item.item_total);
        item_isdeposit.push(item.isdeposit);
        item_id_marketing.push(item.id_marketing);

      });

      // this.currentData.invoice_payment.forEach((item) => {
      //   payment_notes.push(item.payment_notes);
      //   payment_method.push(item.payment_method);
      //   payment_amount.push(item.payment_amount);
      //   payment_date.push(item.payment_date);
      // })


      // yang dicomment udh ada
      formData.append("id", this.currentData.id);
      formData.append("invoice_date", this.convertDate(this.currentData.invoice_date)); //udh ada
      formData.append("due_date", this.convertDate(this.currentData.due_date)); //udh ada 
      formData.append("terms", 0); //kosong
      formData.append("po_number", this.currentData.po_number); //kosong
      formData.append("id_customer", this.currentData.customer_detail.id); //udh ada
      formData.append("subtotal", this.totalAmount);// udh ada 
      formData.append("discount_type", this.currentData.discount_type); //kosong
      formData.append("discount_amount", this.currentData.discount_amount);//kosong
      formData.append("tax_type", this.currentData.tax_type);//kosong
      formData.append("tax_rate", this.currentData.tax_rate);//kosong
      formData.append("tax_name", 'PPN');//kosong
      formData.append("tax_amount", this.currentData.tax_amount);
      formData.append("payment_date", payment_date); //udh ada
      formData.append("payment_amount", payment_amount);
      formData.append("payment_method", payment_method);
      formData.append("payment_notes", payment_notes); //kosong
      formData.append("unpaid_amount", 0);//kosong
      formData.append("grand_total", this.currentData.grand_total); // udh ada 
      formData.append("notes", ''); //kosong
      formData.append("payment_info", ''); //kosong
      formData.append("bank_name", ""); //kosong
      formData.append("bank_account_number", ""); //kosong
      formData.append("bank_account_name", ""); //kosong
      formData.append("calculated_discount", this.currentData.calculated_discount); //kosong


      formData.append("item_id", item_id);// udh ada
      formData.append("item_qty", item_qty); //udh ada
      formData.append("item_rate", item_rate); //udh ada
      formData.append("item_discount_type", item_discount_type); //kosong
      formData.append("item_discount_amount", item_discount_amount); //kosong
      formData.append("item_is_tax", item_is_tax); //kosong
      formData.append("item_name", item_name); //kosong
      formData.append("item_calculated_discount", item_calculated_discount); //kosong
      formData.append("item_total", item_total);// udh ada 
      formData.append("item_isdeposit", item_isdeposit); // udh ada 
      formData.append("item_id_marketing", item_id_marketing);// udh ada
      formData.append("quotation_term", this.currentData.quotation_term);// udh ada
      formData.append("quotation_note", this.currentData.quotation_note);// udh ada
      formData.append("id_marketing", this.currentData.id_marketing);
      formData.append("po_number", this.currentData.po_number);
      formData.append("code_project", this.currentData.code_project);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      // CloseLoading();
      // return false;


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'purchaseorder_insert';
      } else {
        this.api = base_url + 'purchaseorder_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage('purchaseorders');
            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    }


  },

};
</script>
