<template>
  <!-- Header Menu -->
  <ul class="nav user-menu ">
    <!-- Search -->
    <li class="nav-item nav-searchinputs">
      <div class="top-nav-search">
        <a href="javascript:void(0);" class="responsive-search">
          <i class="fa fa-search"></i>
        </a>
        <!-- <form action="#">
          <div class="searchinputs">
            <input type="text" placeholder="Search" />
            <div class="search-addon">
              <span><vue-feather type="search" class="feather-14"></vue-feather></span>
            </div>
          </div>
        </form> -->
      </div>
    </li>

    <!-- <li class="nav-item nav-item-box">
      <vue-select :options="companyList" :settings="{ settingOption: value, settingOption: value }"
        v-model="companyselected" placeholder="None">
      </vue-select>


    </li> -->

    <!-- /Search -->
    <!-- <li class="nav-item nav-item-box">
      <a @click="userGuide()"><vue-feather type="alert-circle"></vue-feather></a>
    </li> -->
    <!-- Flag -->
    <!-- <li class="nav-item dropdown has-arrow flag-nav nav-item-box">
      <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);" role="button">
        <vue-feather type="globe"></vue-feather>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a href="javascript:void(0);" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
          @click="setLanguage(entry.language, entry.title, entry.iso)" :class="{ active: lan === entry.language }"
          class="dropdown-item" data-lang="en" title="English">
          <flag :iso="entry.iso" height="16" class="me-2" />
          <span class="align-middle">{{ entry.title }}</span>
        </a>
      </div>
    </li> -->
    <!-- /Flag -->
    <li class="nav-item nav-item-box">
      <a href="javascript:void(0);" id="btnFullscreen" @click="initFullScreen" ref="butonFullscreen">
        <vue-feather type="maximize"></vue-feather>
      </a>
    </li>

    <li class="nav-item nav-item-box" v-if="whatsapp_id_sent != ''">
      <a href="javascript:void(0);" class="popout-window" id="btnQrcodewhatsapp" @click="DeviceDetailFirst()"
        ref="butonPopoutwindow">
        <i class='bx bxl-whatsapp'></i>
      </a>
    </li>
    <!-- <li class="nav-item nav-item-box">
      <router-link to="email">
        <vue-feather type="mail"></vue-feather>
        <span class="badge rounded-pill">1</span>
      </router-link>
    </li> -->


    <!-- /Notifications -->
    <li class="nav-item nav-item-box" v-if="tipe_user == 'SUP'">
      <router-link to="settingsprofile"><vue-feather type="settings"></vue-feather></router-link>
    </li>
    <li class="nav-item dropdown has-arrow main-drop">
      <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
        <span class="user-info">
          <span class="user-letter" style="background-color: transparent!important;">
            <img :src="gambar_user" alt="" class="img-fluid" />
          </span>
          <span class="user-detail">
            <span class="user-name">{{ nama_user }}</span>
            <span class="user-role">{{ tipe_user }}</span>
          </span>
        </span>
      </a>
      <div class="dropdown-menu menu-drop-user">
        <div class="profilename">
          <div class="profileset">
            <span class="user-img"><img :src="gambar_user" alt="" />
              <span class="status online"></span></span>
            <div class="profilesets">
              <h6>{{ nama_user }}</h6>
              <h5>{{ tipe_user }}</h5>
            </div>
          </div>
          <hr class="m-0" v-if="tipe_user != 'KAS'" />
          <router-link class="dropdown-item" to="profile" v-if="tipe_user != 'KAS'"><vue-feather
              type="user"></vue-feather>My
            Profile</router-link>
          <!-- <router-link class="dropdown-item" to="settingsprofile"><vue-feather
              type="settings"></vue-feather>Settings</router-link> -->
          <hr class="m-0" />
          <a class="dropdown-item logout pb-0" @click="logOut">
            <img src="../assets/img/icons/log-out.svg" class="me-2" alt="img" />Logout
          </a>
          <!-- <router-link class="dropdown-item logout pb-0" to="/">
            <img src="../assets/img/icons/log-out.svg" class="me-2" alt="img" />Logout</router-link> -->
        </div>
      </div>
    </li>
  </ul>

  <!-- modal QR whatsapp -->
  <div class="modal fade" id="qr_code" ref="qr_code" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Scan This QR</h5>
          <button type="button" class="close" @click="hideModal2('qr_code'); stopDeviceDetailInterval()"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- <form @submit.prevent="delete2"> -->
        <div class="modal-body ">
          <div class="d-flex justify-content-center align-items-center">
            <qrcode-vue :value="img_qr" :size="size" level="H" v-if="status_connected == 'disconnected'" />
            <img src="../assets/img/connected.gif" alt="scan QR code" width="300"
              v-if="status_connected == 'connected'">
          </div>
          <div class="d-flex justify-content-center align-items-center mb-3 " v-if="status_connected == 'connected'">
            <h5 class="modal-title">Connected</h5>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-end">
          <button type="button" class="btn  btn-secondary"
            @click="hideModal2('qr_code'); stopDeviceDetailInterval()">close</button>
          <button type="button" class="btn btn-primary" @click="qrCode"
            v-if="status_connected == 'connected'">Reconnect</button>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>

</template>

<style scoped>
.user-img img {
  border-radius: 50%;
  object-fit: cover;
  width: 40px;
  /* Adjust based on your needs */
  height: 40px;
  /* Adjust to keep the aspect ratio square */
}

.user-letter img {
  border-radius: 50%;
  object-fit: cover;
  width: 40px;
  /* Adjust based on your needs */
  height: 40px;
  /* Adjust to keep the aspect ratio square */
}

.popout-window i {
  color: #50C878;
}
</style>

<script>
import headernotification from "../assets/json/headernotification.json";
import Cookies from "js-cookie";
import axios from "axios";
import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, goPage, refreshPage, AlertBottom } from "@/assets/js/function";
import QrcodeVue from 'qrcode.vue'


export default {
  data() {
    return {
      headernotification: headernotification,
      languages: [
        {
          iso: "us",
          language: "us",
          title: "English",
        },
      ],
      get unreadNotif() {
        return parseInt(localStorage.getItem('unreadNotif')) || 0;
      },
      set unreadNotif(value) {
        localStorage.setItem('unreadNotif', value);
      },

      img_qr: "",
      size: 256,
      status_connected: "disconnected",
      whatsapp_id_sent: "",

    };
  },
  components: {
    QrcodeVue,
  },

  props: {
    nama_user: String,
    tipe_user: String,
    gambar_user: String,
    companyList: Object,
    companyselected: String,

  },

  emits: ["companyselected"],
  methods: {

    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
    },
    logOut() {
      ShowLoading();
      // let formData = new FormData();
      // formData.append("", 'pengajuan');
      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'logout';
      } else {
        api = base_url + 'logout';
      }
      const config = {
        headers: { Authorization: `Bearer ` + Cookies.get("token_refresh") },
        timeout: 30000,
      }

      axios
        .post(api, '', config)
        .then((response) => {
          if (response.data.status == true) {

            Cookies.set("token_refresh", "");
            CloseLoading();
            goPage('');
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },


    showModal(modal_name) {
      this.$refs[modal_name].classList.add('show');
      this.$refs[modal_name].style.display = 'block';
    },

    hideModal2(modal_name) {
      this.$refs[modal_name].classList.remove('show');
      this.$refs[modal_name].style.display = 'none';

    },

    qrCode() {
      ShowLoading()

      let formData = new FormData();

      formData.append("metode", "qr_device");

      formData.append("device_id", localStorage.getItem('whatsapp_id_sent'))
      if (process.env.NODE_ENV === "development") {
        this.api = 'https://micro-whatsapp.arthaskyline.com/apiv1';
      } else {
        this.api = 'https://micro-whatsapp.arthaskyline.com/apiv1';
      }
      axios
        .post(this.api, formData)
        .then((response) => {

          if (response.data.status == true) {

            this.img_qr = response.data.data.qr_code;
            this.showModal('qr_code');
            CloseLoading();
            this.status_connected = 'disconnected';
            this.startDeviceDetailInterval();

          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },

    reconnectDevice() {
      ShowLoading()

      let formData = new FormData();

      formData.append("metode", "reconnect_device");

      formData.append("device_id", localStorage.getItem('whatsapp_id_sent'))


      if (process.env.NODE_ENV === "development") {
        this.api = 'https://micro-whatsapp.arthaskyline.com/apiv1';
      } else {
        this.api = 'https://micro-whatsapp.arthaskyline.com/apiv1';
      }


      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            // AlertBottom(response.data.message);
            // this.img_qr = response.data.data.qr_code
            // console.log(this.img_qr)
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },
    DeviceDetailFirst() {
      ShowLoading()

      let formData = new FormData();

      formData.append("metode", "devices");

      formData.append("device_id", localStorage.getItem('whatsapp_id_sent'))


      if (process.env.NODE_ENV === "development") {
        this.api = 'https://micro-whatsapp.arthaskyline.com/apiv1';
      } else {
        this.api = 'https://micro-whatsapp.arthaskyline.com/apiv1';
      }

      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            if (response.data.data.status == 'disconnected') {
              this.qrCode();
            } else {
              this.status_connected = response.data.data.status;
              AlertBottom(response.data.data.status + '(' + response.data.data.phone_number + ')');
              this.showModal('qr_code')
            }
            CloseLoading();

          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },
    DeviceDetail() {
      ShowLoading()

      let formData = new FormData();

      formData.append("metode", "devices");

      formData.append("device_id", localStorage.getItem('whatsapp_id_sent'))


      if (process.env.NODE_ENV === "development") {
        this.api = 'https://micro-whatsapp.arthaskyline.com/apiv1';
      } else {
        this.api = 'https://micro-whatsapp.arthaskyline.com/apiv1';
      }

      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            if (response.data.data.status == 'disconnected') {
            } else {
              this.status_connected = response.data.data.status;
              AlertBottom(response.data.data.status + '(' + response.data.data.phone_number + ')');
              this.stopDeviceDetailInterval();
            }
            CloseLoading();

          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },
    startDeviceDetailInterval() {
      // Call DeviceDetail every 10 seconds (10000 milliseconds)
      this.intervalId = setInterval(this.DeviceDetail, 10000);
    },
    stopDeviceDetailInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },


    userGuide() {
      window.open('https://support.arthaskyline.com/', "_blank");
    }
  },
  created() {
    if (localStorage.getItem('unreadNotif') == null) {
      localStorage.setItem('unreadNotif', 0);
    }

    if (localStorage.getItem('whatsapp_id_sent') != null) {
      this.whatsapp_id_sent = localStorage.getItem('whatsapp_id_sent');
    }
  },

};
</script>
