<template>
    <div class="bg-all">
        <div class="container mt-3">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 flex-item">
                    <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center">
                        <!-- <div class="bg-header"></div> -->
                        <img :src="currentData.logo" width="150" height="150" v-if="currentData != []">
                        <img src="https://levelup.arthaskyline.com/assets/img/custom/logo_small_color.png" width="150"
                            height="150" v-else>
                        <h2 class="m-3 mt-4">Rate your experience</h2>
                        <p>We highly value your feedback! Kindly take a moment to rate your experience and provide us
                            with
                            your valuable feedback.</p>

                    </div>

                    <!-- post result -->

                    <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center" v-if="isSubmit == true">
                        <p>Thanks for your feedback.</p>
                    </div>

                    <div v-if="isSubmit == false && isSlugCorrect == true">
                        <!-- get result -->
                        <form @submit.prevent="submit">
                            <input type="hidden" name="logo" value="<?= $logo ?>">
                            <input type="hidden" name="token" value="<?= $_GET['token'] ?>">
                            <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center">
                                <h3>Kebersihan</h3>
                                <div class="rating" style="margin-top:-10px;">
                                    <input type="radio" id="star5" name="rating1" value="5" v-model="kebersihan" />
                                    <label for="star5" title="5 stars">&#9733;</label>
                                    <input type="radio" id="star4" name="rating1" value="4" v-model="kebersihan" />
                                    <label for="star4" title="4 stars">&#9733;</label>
                                    <input type="radio" id="star3" name="rating1" value="3" v-model="kebersihan" />
                                    <label for="star3" title="3 stars">&#9733;</label>
                                    <input type="radio" id="star2" name="rating1" value="2" v-model="kebersihan" />
                                    <label for="star2" title="2 stars">&#9733;</label>
                                    <input type="radio" id="star1" name="rating1" value="1" v-model="kebersihan" />
                                    <label for="star1" title="1 star">&#9733;</label>
                                </div>
                            </div>

                            <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center">
                                <h3>Kenyamanan</h3>
                                <div class="rating" style="margin-top:-10px;">
                                    <input type="radio" id="star25" name="rating2" value="5" v-model="kenyamanan" />
                                    <label for="star25" title="5 stars">&#9733;</label>
                                    <input type="radio" id="star24" name="rating2" value="4" v-model="kenyamanan" />
                                    <label for="star24" title="4 stars">&#9733;</label>
                                    <input type="radio" id="star23" name="rating2" value="3" v-model="kenyamanan" />
                                    <label for="star23" title="3 stars">&#9733;</label>
                                    <input type="radio" id="star22" name="rating2" value="2" v-model="kenyamanan" />
                                    <label for="star22" title="2 stars">&#9733;</label>
                                    <input type="radio" id="star21" name="rating2" value="1" v-model="kenyamanan" />
                                    <label for="star21" title="1 star">&#9733;</label>
                                </div>
                            </div>

                            <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center">
                                <h3>Terapis</h3>
                                <div class="rating" style="margin-top:-10px;">
                                    <input type="radio" id="star35" name="rating3" value="5" v-model="terapis" />
                                    <label for="star35" title="5 stars">&#9733;</label>
                                    <input type="radio" id="star34" name="rating3" value="4" v-model="terapis" />
                                    <label for="star34" title="4 stars">&#9733;</label>
                                    <input type="radio" id="star33" name="rating3" value="3" v-model="terapis" />
                                    <label for="star33" title="3 stars">&#9733;</label>
                                    <input type="radio" id="star32" name="rating3" value="2" v-model="terapis" />
                                    <label for="star32" title="2 stars">&#9733;</label>
                                    <input type="radio" id="star31" name="rating3" value="1" v-model="terapis" />
                                    <label for="star31" title="1 star">&#9733;</label>
                                </div>
                            </div>

                            <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center">
                                <h3>Kritik & Saran</h3>
                                <textarea name="saran" class="form-control" rows="5" v-model="saran"></textarea>
                            </div>

                            <button type="submit" class="mt-3 btn btn-warning form-control">Submit</button>
                        </form>
                    </div>
                    <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center" v-if="isSlugCorrect == false">
                        <p>Sorry Token is not valid.</p>
                    </div>



                    <p style="font-size:9px;" class="text-muted mt-3 mb-5 text-center">&copy;
                        {{ currentYear }}ArthaSkyline.com<br>PT. SHANKARA CABOT BAJRA DANAPATI</p>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
body {
    background-color: #f6f6f6 !important;
    height: 100% !important;
}

.bg-all {
    background-color: #f6f6f6 !important;
    height: 100% !important;
}

.bg-header {
    background-color: #dc3545 !important;
    color: #ffffff !important;
    height: 10px;
    margin: -9px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.border-radius-8 {
    border-radius: 8px !important;
}

.btn-danger {
    color: #ffffff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-outline-danger {
    color: #ffffff !important;
    border-color: #ffffff !important;
}

.text-muted {
    color: #cacaca !important;
}

.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
}

.rating>label {
    display: inline-block;
    position: relative;
    width: 1.1em;
    font-size: 2.5em;
    color: #aaa;
    cursor: pointer;
}

.rating>label:hover,
.rating>label:hover~label,
.rating>input:focus~label {
    color: orange;
}

.rating>input:checked~label,
.rating:not(:checked)>label:hover~label {
    color: orange;
}

.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
.rating>input:checked~label:hover~label {
    color: #FFD700;
}

/* Hide the inputs */
.rating>input {
    display: none;
}

/* Show the inputs with a higher z-index, to place them over the stars */
.rating>input:checked+label {
    z-index: 2;
}
</style>

<script>
import { ref } from "vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import Cookies from "js-cookie";
import axios from "axios";


import { ShowLoading, CloseLoading, formatDateToString, ErrorConnectionTimeOut, base_url, AlertPopup, rupiah, goPage, DateFormat } from "@/assets/js/function";


export default {

    data() {
        return {
            currentData: [],
            currentYear: "",
            api: "",
            config: '',
            isCustomer: false,
            isSubmit: false,
            isSlugCorrect: false,

            kebersihan: "",
            kenyamanan: "",
            terapis: "",
            saran: "",

        }

    },
    mounted() {

        const token = localStorage.getItem('token_feedback');
        this.loadDataFromLocalToken(token);
        // // Get the current year
        // this.currentYear = new Date().getFullYear();

    },
    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }

    },

    methods: {
        formatDateToString,
        rupiah,
        DateFormat,
        goPage,
        submit() {
            ShowLoading();
            let formData = new FormData();
            formData.append("token", localStorage.getItem('token_feedback').replace(/"/g, ""));
            formData.append("rating1", this.kebersihan);
            formData.append("rating2", this.kenyamanan);
            formData.append("rating3", this.terapis);
            formData.append("saran", this.saran);



            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // return false;

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'feedback_insert';
            } else {
                this.api = base_url + 'feedback_insert';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.isSubmit = true;
                        CloseLoading();
                        localStorage.setItem('slug', '');
                        localStorage.setItem('token_feedback', '');
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });
        },
        loadDataFromLocalToken() {
            ShowLoading();
            let formData = new FormData();
            formData.append("slug", localStorage.getItem('token_feedback'));


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // return false;

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'invoices_slug';
            } else {
                this.api = base_url + 'invoices_slug';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response);
                    if (response.data.status == true) {
                        this.currentData = response.data.data.invoices[0];
                        if (parseInt(this.currentData.rating1) > 0) {
                            this.isSubmit = true;
                            localStorage.setItem('slug', '');
                            localStorage.setItem('token_feedback', '');
                        }
                        this.isSlugCorrect = true;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                        // this.currentData.logo = "https://levelup.arthaskyline.com/assets/img/custom/logo_small_color.png";
                        // goPage("index");
                        window.location.href = "https://arthaskyline.com/level-up.html"

                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });
        },


    },

    name: "viewinvoice",
};
</script>