<template>
    <form @submit.prevent="additem">
        <div class="modal fade" id="modaladditeminvoice" tabindex="-1" aria-labelledby="modaladditeminvoice"
            aria-hidden="true">

            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title">{{ title }}
                            </h5>
                            <span for=""> {{ sub }} </span>
                        </div>

                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                            ref="buttonclosemodaladditeminvoice">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- <div class="page-header">
                            <div class="page-title">
                                <h4>Categories</h4>
                                <h6>Manage your purchases</h6>
                            </div>
                        </div> -->


                        <div class="row">
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Select Category</label>
                                        <model-select :options="kategoridata" placeholder="Select Category"
                                            v-model="kategori_id" required :isError="kategoriCek">
                                        </model-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Select Item</label>
                                        <model-select :options="itemdata" placeholder="Select item" v-model="item_id"
                                            :isDisabled="kategori_id == ''" :isError="itemCek">
                                        </model-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" v-if="isMarketing == true">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Select Marketing</label>
                                        <model-select :options="marketingdata" placeholder="Select Marketing"
                                            v-model="marketing_id" :isError="marketingCek">
                                        </model-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Qty </label>
                                        <input type="number" class="form-control form-control-sm"
                                            aria-controls="DataTables_Table_0" v-model="qty" min="1" required />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12">
                            <button class="btn btn-submit me-2" type="submit">Save</button>
                            <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<style></style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ModelSelect } from 'vue-search-select';




import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, rupiah, goPage, AlertBottom } from "@/assets/js/function";
import { boolean } from "yup";

export default {
    data() {
        return {
            //title
            title: "",
            sub: "",


            data: "",
            config: "",
            token: "",

            //add items
            marketing_id: "",
            kategori_id: "",
            item_id: "",
            qty: 1,
            marketingCek: false,
            kategoriCek: false,
            itemCek: false,
            status: "",
            id: "",

            //pilih kategori
            active_id: "",
            settings: {
                itemsToShow: 10,
                snapAlign: "center",
            },
            breakpoints: {
                0: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                575: {
                    itemsToShow: 4,
                    snapAlign: "center",
                },
                // 700px and up
                767: {
                    itemsToShow: 6,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 8,
                    snapAlign: "start",
                },
            },
            modalClass: "",

            //sent add item 

        }

    },

    props: {
        marketingdata: Object,
        kategoridata: Object,
        itemdata: Object,
        itemEdited: Object,
        isMarketing: Boolean,

    },
    watch: {
        kategori_id(newValue, oldValue) {
            this.kategoriCek = false;
            this.$emit('id_kategori_selected', newValue)
        },
        item_id(newValue, oldValue) {
            this.itemCek = false;
            const index = this.itemdata.findIndex((record) => record.id === newValue);
            let isMarketing = false;

            if (this.itemdata[index] != undefined && this.itemdata[index].ismarketing == 'AKTIF') {
                isMarketing = true;
            }
            this.$emit('id_item_selected', isMarketing);


        },
        marketing_id(newValue, oldValue) {
            this.marketingCek = false;
            // this.$emit('id_marketing_selected', newValue)
        },
        itemEdited: {
            handler(newVal) {
                if (newVal) {
                    this.marketing_id = newVal.marketing_id;
                    this.kategori_id = newVal.kategori_id;
                    this.item_id = newVal.item_id;
                    this.qty = newVal.qty;
                    this.status = newVal.status;
                    this.id = newVal.id;

                    if (newVal.item_id != "") {
                        this.title = "Edit Item";
                        this.sub = "Update data item";
                    } else {
                        this.title = "Select Item";
                        this.sub = "Add item";
                    }
                }

            },

        }

    },

    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
    },


    methods: {
        rupiah,

        additem() {
            if (this.isMarketing == true && this.marketing_id == '') {
                this.marketingCek = true;
            }
            if (this.kategori_id == '') {
                this.kategoriCek = true;
            }
            if (this.item_id == '') {
                this.itemCek = true;
            }

            if (this.kategoriCek == true || this.itemCek == true || (this.isMarketing == true && this.marketing_id == '')) {
                AlertPopup('error', '', 'Some fields are empty', 2000, true);
                return true;
            }

            const idmarketing = this.marketing_id != '' || this.marketing_id > 0 ? this.marketing_id : 0;
            const item_object = {
                id_items: this.item_id,
                id_marketing: idmarketing,
                qty: this.qty,
                id: this.id,
                status: this.status,
            }
            this.$emit('item_object', item_object)
            const openButton = this.$refs.buttonclosemodaladditeminvoice;
            openButton.click();

        },

    },
    components: {

        ModelSelect

    },
}
</script>