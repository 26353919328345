<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Document No </label>
                    <input type="text" v-model="currentData.document_no" placeholder="" disabled required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Created Date </label>
                    <input type="text" v-model="currentData.created_at" placeholder="" disabled required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama_project" placeholder="" @change="insert()" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Quotation</label>

                    </div>
                    <model-select :options="data_quotation" placeholder="Select Quotation"
                      v-model="this.currentData.id_quotations" style="z-index:505">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Marketing</label>

                    </div>
                    <!-- <model-select :options="data_marketing" placeholder="Select Marketing"
                      v-model="this.currentData.id_marketing" style="z-index:500" :isError="marketingCek">
                    </model-select> -->
                    <multi-select :options="data_marketing" :selected-options="marketings" style="z-index:504"
                      placeholder="select marketing" @select="onSelectMarketings">
                    </multi-select>
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Presales</label>

                    </div>
                    <!-- <model-select :options="data_marketing" placeholder="Select presales"
                      v-model="this.currentData.id_presales" style="z-index:500">
                    </model-select> -->
                    <multi-select :options="data_marketing" :selected-options="presales" style="z-index:503"
                      placeholder="select presales" @select="onSelectPresales">
                    </multi-select>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Development</label>

                    </div>
                    <!-- <model-select :options="data_marketing" placeholder="Select development"
                      v-model="this.currentData.id_development" style="z-index:500">
                    </model-select> -->
                    <multi-select :options="data_marketing" :selected-options="developments" style="z-index:502"
                      placeholder="select developer" @select="onSelectDevelopments">
                    </multi-select>
                  </div>
                </div>


                <!-- <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Update</button>
                  <router-link to="discussions" class="btn btn-cancel">Cancel</router-link>
                </div> -->
              </div>

            </form>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <!-- <pageheader title="summary baru" :title1="" /> -->
            <form @submit.prevent="summary_insert" ref="form_insert_summary">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between">
                      <div>
                        <label>Summary <span class="text-danger">*</span></label>
                      </div>
                      <div>
                        <!-- 
                        <button class="btn btn-sm btn-primary mb-2" type="button" @click="loadRecentSummary">
                          <MDBIcon icon="history " />
                        </button> -->

                      </div>
                    </div>

                    <textarea class="form-control" v-model="currentDataSummary.summary" @input="saveLocalSummary">
                    </textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Document</label>
                    <input type="file" class="form-control" ref="document" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Save</button>
                  <!-- <router-link to="discussions" class="btn btn-cancel" >Cancel</router-link> -->
                </div>
              </div>
            </form>
          </div>
        </div>


        <div class="card" v-for="item in currentData.form_konsultasi_detail" :key="item.id">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-start">
              <div>
                <pageheader :title="item.fullname" title1="" />
              </div>
              <div style="color:rgba(50, 65, 72, 0.4)" class="d-flex gap-4 align-items-center ">
                <div>
                  {{ item.created_at }}
                </div>
                <div class="mt-1">
                  <vue-feather type="trash-2" style="width:16px; cursor:pointer"
                    @click="terminate(item.id); showModal('terminate');"></vue-feather>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group ms-4">
                  <!-- <label class="fw-bold">Summary </label> -->
                  <!-- <textarea class="form-control" v-model="item.summary">
                    </textarea> -->
                  <p class="text-justify" v-html="formatText(item.summary)">

                  </p>
                </div>
              </div>
              <div v-if="item.document_url != null" class="col-12">
                <div class="form-group ms-4">
                  <div> <a href="javascript:void(0);" @click="openInNewTab(item.document_url)"
                      class="me-3 d-flex align-items-center gap-2">
                      <div>
                        <vue-feather type="paperclip"></vue-feather>
                      </div>
                      <div>
                        {{ item.document_url }}
                      </div>
                    </a></div>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


  <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Delete Data</h5>
          <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="delete2">
          <div class="modal-body">
            Are u sure want to delete this data?
          </div>
          <div class="modal-footer d-flex justify-content-end">
            <button type="button" class="btn  btn-secondary" @click="hideModal2('terminate')">cancel</button>
            <button type="submit" class="btn  btn-danger">Delete</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.text-justify {
  text-align: justify !important;
}

.text-justify::after {
  content: '' !important;
  display: inline-block !important;
  width: auto !important;
  /* Ensures that the space is filled */
}
</style>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, openInNewTab } from "@/assets/js/function";
import { ModelSelect, MultiSelect } from 'vue-search-select';
import { MDBIcon } from "mdb-vue-ui-kit";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {

    ModelSelect,
    MultiSelect,
    MDBIcon,
  },

  name: "editbank",
  data() {

    return {
      filter: true,
      title: "Edit Discussion",
      title1: "edit Discussion data",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      initialize: false,

      //data
      currentData: {

        form_konsultasi_detail: [],
      },
      data_marketing: [],



      //marketing multi select
      marketings: [],
      searchText: '', // If value is falsy, reset searchText & searchItem
      lastSelectItemMarketing: {},

      //presales multi select
      presales: [],
      lastSelectItemSales: {},

      //development
      developments: [],
      lastSelectItemDevelopment: {},

      data_quotation: [],
      data_spk: [],

      //summary 
      currentDataSummary: {
        summary: "",
        document: "",
      },

      //delete
      id_terminate: "",


      currentDate: new Date,
    };
  },
  updated() {
    this.initialize = true;


  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //get data
    this.currentData = JSON.parse(localStorage.getItem('currentData'));
    this.getData();

    this.form_konsultasi_detail(this.currentData.id);



  },
  watch: {
    'currentData.id_quotations': {
      handler(newValue) {
        if (this.initialize) {
          this.insert();
        }
      }
    },
    'currentData.id_marketing': {
      handler(newValue) {
        this.marketingCek = false;
        if (this.initialize) {
          this.insert();
        }
      }
    },
    'currentData.id_presales': {
      handler(newValue) {
        if (this.initialize) {
          this.insert();
        }
      }
    },
    'currentData.id_development': {
      handler(newValue) {
        if (this.initialize) {
          this.insert();
        }
      }
    },
  },
  methods: {
    openInNewTab,
    onSelectMarketings(items, lastSelectItem) {
      this.marketings = items
      this.lastSelectItemMarketing = lastSelectItem
      this.insert();
    },
    onSelectPresales(items, lastSelectItem) {
      this.presales = items
      this.lastSelectItemPresales = lastSelectItem
      this.insert();
    },
    onSelectDevelopments(items, lastSelectItem) {
      this.developments = items
      this.lastSelectItemDevelopment = lastSelectItem
      this.insert();
    },
    // deselect option
    reset() {
      this.marketings = [] // reset
    },
    loadRecentSummary() {

      this.currentDataSummary.summary = localStorage.getItem('summary');
    },
    saveLocalSummary() {
      localStorage.setItem('summary', this.currentDataSummary.summary);
    },
    // convertNewlinesToBr(text) {
    //   // Replace newlines with <br> tags
    //   return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    // },
    formatText(text) {
      // Regular expression to match URLs and replace them with anchor tags
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Replace newlines with <br> tags and then replace URLs with anchor tags
      const formattedText = text.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(urlRegex, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    getData() {
      const marketing = JSON.parse(localStorage.getItem('marketing'));
      console.log(marketing);
      marketing.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.fullname };
        this.data_marketing.push(item_prepare)
      });

      const quotation = JSON.parse(localStorage.getItem('quotation'));
      quotation.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.invoice_no + ' - ' + item.customer_name };
        this.data_quotation.push(item_prepare)
      });

      this.currentDataSummary.summary = localStorage.getItem('summary');

    },
    form_konsultasi_detail(id) {
      this.currentData.form_konsultasi_detail = [];
      ShowLoading();

      let formData = new FormData();

      formData.append('id', id);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'form_konsultasi_detail';
      } else {
        this.api = base_url + 'form_konsultasi_detail';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {

            this.currentData = response.data.data.form_konsultasi[0];
            this.currentData.form_konsultasi_detail = response.data.data.form_konsultasi_detail;

            const arrayIdMarketing = this.currentData.id_marketing.split(',');

            this.marketings = [];
            for (let i = 0; i < arrayIdMarketing.length; i++) {
              const index = this.data_marketing.findIndex((record) => record.value == arrayIdMarketing[i]);

              const item_prepare = { value: this.data_marketing[index].value, text: this.data_marketing[index].text };
              this.marketings.push(item_prepare);
            };

            this.presales = [];
            const arrayIdPresales = this.currentData.id_presales.split(',');

            for (let i = 0; i < arrayIdPresales.length; i++) {
              const index = this.data_marketing.findIndex((record) => record.value == arrayIdPresales[i]);
              const item_prepare = { value: this.data_marketing[index].value, text: this.data_marketing[index].text };
              this.presales.push(item_prepare);
            };
            this.developments = [];
            const arrayIdDevelopment = this.currentData.id_development.split(',');

            for (let i = 0; i < arrayIdDevelopment.length; i++) {
              const index = this.data_marketing.findIndex((record) => record.value == arrayIdDevelopment[i]);
              const item_prepare = { value: this.data_marketing[index].value, text: this.data_marketing[index].text };
              this.developments.push(item_prepare);
            };

            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });

    },

    summary_insert() {
      ShowLoading();

      let formData = new FormData();
      Object.entries(this.currentDataSummary).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('id_form', this.currentData.id);
      formData.delete("document");

      if (this.$refs.document.value != "") {
        formData.append("document", this.$refs.document.files[0]);
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'form_konsultasi_insert_summary';
      } else {
        this.api = base_url + 'form_konsultasi_insert_summary';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            this.currentDataSummary = { summary: "" }
            this.$refs.form_insert_summary.reset();
            this.form_konsultasi_detail(this.currentData.id);

            localStorage.setItem('summary', '');
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    insert() {
      ShowLoading();

      if (this.currentData.id_marketing == '') {
        this.marketingCek = true;
        AlertPopup('error', '', 'Please select marketing', 2000, true);
        return true;
      }

      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      let id_marketing = [];
      this.marketings.forEach((item, index) => {
        id_marketing.push(item.value)
      });

      let id_presales = [];
      this.presales.forEach((item, index) => {
        id_presales.push(item.value)
      });
      let id_development = [];
      this.developments.forEach((item, index) => {
        id_development.push(item.value)
      });

      formData.append('id_marketing', id_marketing.join(','))
      formData.append('id_development', id_development.join(','))
      formData.append('id_presales', id_presales.join(','))

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'form_konsultasi_update';
      } else {
        this.api = base_url + 'form_konsultasi_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);


            // goPage("discussions");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    delete2() {
      ShowLoading();
      let formData = new FormData();
      formData.append("id", this.id_terminate);



      // for (let [key, value] of formData.entries()) {
      //     console.log(`${key}: ${value}`);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'form_konsultasi_delete_summary';
      } else {
        this.api = base_url + 'form_konsultasi_delete_summary';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {

            AlertBottom(response.data.message);
            this.hideModal2('terminate');
            this.form_konsultasi_detail(this.currentData.id);
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
            this.hideModal2('terminate');
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });


    },
    terminate(id) {
      this.id_terminate = id;
    },
    showModal(modal_name) {
      this.$refs[modal_name].classList.add('show');
      this.$refs[modal_name].style.display = 'block';
    },

    hideModal2(modal_name) {
      this.$refs[modal_name].classList.remove('show');
      this.$refs[modal_name].style.display = 'none';

    },

  },

};
</script>
