<template>
    <div class="container mt-3" style="font-size: 16px!important;">

        <div class="row d-flex justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12  flex-item">
                <div class="w-100 mt-3 p-2 bg-white border-radius-8 text-center">
                    <!-- <div class="bg-header"></div> -->
                    <img :src="currentData.logo" width="150" height="150" v-if="currentData.length > 0">
                    <img src="https://levelup.arthaskyline.com/assets/img/custom/logo_small_color.png" width="150"
                        height="150" v-else>

                    <!-- <h2 class="m-3 mt-4">INVOICE</h2> -->

                    <div class="d-flex justify-content-center align-items-end text-primary fw-bold fs-5">
                        Petty Cash Out

                    </div>
                    <hr>
                    <!-- table pc/tablet -->
                    <table
                        class="w-100 table border-bottom border-secondary border-1 d-md-block d-sm-block d-lg-block d-xl-block d-none">
                        <tr>
                            <td style="width: 75%;" class="text-start"><span class="text-uppercase fw-bold"
                                    v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.fullname }}
                                </span></td>

                            <td class="text-start" style="width: 12.5%;">
                                Petty Cash Out No
                            </td>

                            <td class="text-end" style="width: 12.5%;">

                                {{ currentData.invoice_no }}
                            </td>

                        </tr>

                        <tr>
                            <td class="text-start"><span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.whatsapp }}
                                </span></td>

                            <td class="text-start">
                                Petty Cash Out Date
                            </td>

                            <td class="text-end">

                                {{ currentData.invoice_date }}
                            </td>

                        </tr>
                        <tr>
                            <td class="text-start"><span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.telepone }}
                                </span></td>
                            <td class="text-start">
                                Due Date
                            </td>
                            <td class="text-end">

                                {{ currentData.due_date }}
                            </td>


                        </tr>
                        <tr>
                            <td class="text-start"><span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.email }}
                                </span></td>
                            <td class="text-start">
                                <label v-if="currentData.po_number">PO No.</label>
                            </td>
                            <td class="text-end">
                                <label v-if="currentData.po_number">{{ currentData.po_number }}</label>
                            </td>


                        </tr>
                    </table>

                    <table
                        class="w-100 table border-bottom border-secondary border-1 d-block d-md-none d-sm-none d-lg-none d-xl-none ">
                        <tr>
                            <td class="text-start" style="width: 50%;">
                                Petty Cash Out No
                            </td>
                            <td class="text-end" style="width: 50%;">
                                {{ currentData.invoice_no }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-start">
                                Petty Cash Out Date
                            </td>
                            <td class="text-end">

                                {{ currentData.invoice_date }}
                            </td>

                        </tr>
                        <tr>
                            <td class="text-start">
                                Due Date
                            </td>
                            <td class="text-end">
                                {{ currentData.due_date }}
                            </td>

                        </tr>
                        <tr v-if="currentData.po_number != ''">
                            <td class="text-start">
                                PO No.
                            </td>
                            <td class="text-end">
                                {{ currentData.po_number }}
                            </td>

                        </tr>

                        <tr>
                            <td class="text-start"><span class="text-uppercase fw-bold"
                                    v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.fullname }}
                                </span></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td class="text-start"><span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.telepone }}
                                </span></td>
                            <td class="text-end"></td>
                        </tr>
                        <tr>
                            <td class="text-start">
                                <span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.whatsapp }}
                                </span>
                            </td>
                            <td class="text-end"> </td>
                        </tr>
                        <tr>
                            <td class="text-start">
                                <span class="text-uppercase " v-if="currentData.id_customer > 0">
                                    {{ currentData.customer_detail.email }}
                                </span>
                            </td>
                            <td class="text-end">
                            </td>
                        </tr>
                    </table>

                    <div class="table-responsive">
                        <table class="w-100 table border-bottom border-secondary border-1 mt-3">
                            <tr class="border-bottom border-secondary border-1 fw-bold">

                                <th class="text-start pe-4">ITEM</th>
                                <th class="text-end pe-4">QTY</th>
                                <th class="text-end pe-4">PRICE</th>

                                <th class="text-end pe-4">TOTAL</th>
                            </tr>
                            <tr v-for="item in currentData.invoice_detail" :key="item.id"
                                class="border-bottom border-secondary border-1">
                                <td class="text-start pe-4" style="min-width: 200px!important;">
                                    <span class="fw-bold">{{ item.name }}</span><br>


                                    <span v-html="item.deskripsi">

                                    </span>
                                    <br>
                                    <span style="font-style: italic!important;" v-if="item.nama_marketing != ''">*{{
                        item.nama_marketing }}</span>

                                </td>


                                <td class="text-end pe-4">

                                    {{ item.qty }}

                                </td>
                                <td class="text-end pe-4">
                                    {{ rupiah(item.rate) }}

                                </td>
                                <td class="text-end pe-4">
                                    {{ rupiah(item.item_total) }}

                                </td>
                            </tr>
                            <!-- <?php foreach ($data_invoices as $dd) { ?>
                        <tr>
                            <td class="text-start">
                                <?= $dd['qty'] ?>X<br>
                                <?= $dd['name'] ?><br>
                                <?= $dd['description'] ?>
                            </td>
                            <td class="text-end">
                                <?= number_format($dd['rate'],0) ?>
                            </td>
                            <td class="text-end">
                                <?= number_format($dd['item_total'],0) ?>
                            </td>
                        </tr>
                        <?php } ?> -->
                        </table>
                    </div>

                    <table class="w-100 table  border-bottom border-secondary border-1 mt-3">
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Net</td>
                            <td class="text-end">
                                {{ rupiah(currentData.subtotal) }}
                                <!-- <?= number_format($data['subtotal'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Subtotal</td>
                            <td class="text-end">
                                {{ rupiah(currentData.subtotal) }}
                                <!-- <?= number_format($data['subtotal'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Discount</td>
                            <td class="text-end">
                                {{ rupiah(currentData.discount_amount) }}
                                <!-- <?= number_format($data['subtotal'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">{{ currentData.tax_name }}</td>
                            <td class="text-end">
                                {{ rupiah(currentData.tax_rate) }}
                                <!-- <?= number_format($data['subtotal'],0) ?> -->
                            </td>
                        </tr>
                        <tr class="border-bottom border-secondary border-1">
                            <td class="text-start fw-bold">Total</td>
                            <td class="text-end fw-bold">
                                {{ rupiah(currentData.grand_total) }}
                                <!-- <?= number_format($data['grand_total'],0) ?> -->
                            </td>
                        </tr>


                    </table>
                    <div class="my-4 text-start">
                        <span class="fw-bold">Payment term</span>

                        <p class="text-justify" v-html="formatText(currentData.quotation_term)"></p>

                    </div>
                    <div class="my-4 text-start">
                        <span class="fw-bold">Petty Cash Out note</span>

                        <p class="text-justify" v-html="formatText(currentData.quotation_note)"></p>
                    </div>
                    <div class="my-4 text-start">
                        <span class="fw-bold">Payment Info</span>
                        <br>
                        {{ currentData.bank_name + ' , ' + currentData.bank_account_number + ' , ' +
                        currentData.bank_account_name }}

                    </div>
                </div>

                <p style="font-size:9px;" class="text-muted mt-3 mb-5 text-center">
                    &copy;{{ currentYear }}ArthaSkyline.com<br>PT. SHANKARA CABOT BAJRA DANAPATI
                </p>

            </div>
        </div>
    </div>
</template>

<style scoped>
.table>:not(caption)>*>* {
    padding: 0px !important;
}

table {
    border-collapse: separate;
    border-spacing: 0 1em;
}

.bg-all {
    background-color: #f6f6f6 !important;
}

.table {
    border-bottom-width: 1px !important;
}

.bg-header {
    background-color: #dc3545 !important;
    color: #ffffff !important;
    height: 10px;
    margin: -9px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.border-radius-8 {
    border-radius: 8px !important;
}

.btn-danger {
    color: #ffffff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-outline-danger {
    color: #ffffff !important;
    border-color: #ffffff !important;
}

.text-muted {
    color: #cacaca !important;
}

.text-primary {
    color: #1ba0e2 !important;
}
</style>

<script>
import { ref } from "vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import Cookies from "js-cookie";
import axios from "axios";


import { ShowLoading, CloseLoading, formatDateToString, ErrorConnectionTimeOut, base_url, AlertPopup, rupiah, goPage, DateFormat } from "@/assets/js/function";


export default {

    data() {
        return {
            currentData: {
                quotation_note: "",
                quotation_term: "",
            },
            currentYear: "",
            api: "",
            config: '',
            isCustomer: false,
            token: "",

        }

    },
    mounted() {
        var url = window.location.href;

        // Create a new URL object
        var urlObject = new URL(url);

        // Get the value of the 'token' parameter
        var token = urlObject.searchParams.get('token');
        this.token = token;
        console.log('test token');
        console.log(token);
        if (token == null) {
            this.loadDataFromLocalToken();
        } else {
            this.isCustomer = true;
            this.loadDataFromUrlToken(token);
        }


        // // Get the current year
        // this.currentYear = new Date().getFullYear();

    },
    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }

    },

    methods: {
        formatDateToString,
        rupiah,
        DateFormat,
        goPage,
        formatText(text) {
            // Regular expression to match URLs and replace them with anchor tags
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            if (text != null) {


                // Replace newlines with <br> tags and then replace URLs with anchor tags
                const formattedText = text.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(urlRegex, '<a href="$1" target="_blank">$1</a>');

                return formattedText;
            }
            else {
                return "";
            }
        },
        berikanUlasan() {
            localStorage.setItem('token_feedback', this.token);
            goPage('feedback');
        },
        loadDataFromLocalToken() {
            ShowLoading();
            let formData = new FormData();
            formData.append("slug", localStorage.getItem('slug'));


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // return false;

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'quotations_slug';
            } else {
                this.api = base_url + 'quotations_slug';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data);

                    if (response.data.status == true) {
                        this.currentData = response.data.data.invoices[0];
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                        window.location.href = "https://arthaskyline.com/level-up.html";

                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });
        },
        loadDataFromUrlToken(token) {
            console.log('dari url')
            ShowLoading();
            let formData = new FormData();
            formData.append("slug", token);



            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // return false;

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'quotations_slug';
            } else {
                this.api = base_url + 'quotations_slug';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.currentData = response.data.data.invoices[0];

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                        window.location.href = "https://arthaskyline.com/level-up.html";
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });
        }

    },

    name: "viewinvoice",
};
</script>