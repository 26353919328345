<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama_project" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Quotation</label>

                    </div>
                    <model-select :options="data_quotation" placeholder="Select Quotation"
                      v-model="this.currentData.id_quotations" style="z-index:506">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Marketing <span class="text-danger">*</span></label>

                    </div>
                    <!-- <model-select :options="data_marketing" placeholder="Select Marketing"
                      v-model="this.currentData.id_marketing" style="z-index:500" :isError="marketingCek">
                    </model-select> -->
                    <multi-select :options="data_marketing" :selected-options="marketings" style="z-index:504"
                      placeholder="select marketing" @select="onSelectMarketings">
                    </multi-select>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Presales</label>

                    </div>
                    <!-- <model-select :options="data_marketing" placeholder="Select presales"
                      v-model="this.currentData.id_presales" style="z-index:500">
                    </model-select> -->
                    <multi-select :options="data_marketing" :selected-options="presales" style="z-index:503"
                      placeholder="select presales" @select="onSelectPresales">
                    </multi-select>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Development</label>

                    </div>
                    <!-- <model-select :options="data_marketing" placeholder="Select development"
                      v-model="this.currentData.id_development" style="z-index:500">
                    </model-select> -->
                    <multi-select :options="data_marketing" :selected-options="developments" style="z-index:502"
                      placeholder="select developer" @select="onSelectDevelopments">
                    </multi-select>
                  </div>
                </div>

                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Account Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama_account" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Account Number <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nomor_rekening" placeholder="" required />
                  </div>
                </div> -->

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Insert</button>
                  <router-link to="discussions" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { ModelSelect, MultiSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {

    ModelSelect,
    MultiSelect

  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Insert Discussion",
      title1: "add new Discussion",
      config: "",
      api: "",

      //data
      currentData: {
        id_marketing: "",
      },
      data_marketing: [],

      //marketing multi select
      marketings: [],
      searchText: '', // If value is falsy, reset searchText & searchItem
      lastSelectItemMarketing: {},

      //presales multi select
      presales: [],
      lastSelectItemSales: {},

      //development
      developments: [],
      lastSelectItemDevelopment: {},

      data_quotation: [],
      data_spk: [],



      marketingCek: false,

      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.getData();


  },

  watch: {
    'currentData.id_marketing': {
      handler(newValue) {
        this.marketingCek = false;
      }
    }
  },
  methods: {
    onSelectMarketings(items, lastSelectItem) {
      this.marketings = items
      this.lastSelectItemMarketing = lastSelectItem
    },
    onSelectPresales(items, lastSelectItem) {
      this.presales = items
      this.lastSelectItemPresales = lastSelectItem
    },
    onSelectDevelopments(items, lastSelectItem) {
      this.developments = items
      this.lastSelectItemDevelopment = lastSelectItem
    },
    // deselect option
    reset() {
      this.marketings = [] // reset
    },

    getData() {
      const marketing = JSON.parse(localStorage.getItem('marketing'));
      marketing.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.fullname };
        this.data_marketing.push(item_prepare)
      });

      const quotation = JSON.parse(localStorage.getItem('quotation'));
      quotation.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.invoice_no + ' - ' + item.customer_name };
        this.data_quotation.push(item_prepare)
      });

    },

    insert() {
      ShowLoading();
      if (this.marketings == []) {
        this.marketingCek = true;
        AlertPopup('error', '', 'Please select marketing', 2000, true);
        return true;
      }

      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete('id_marketing');
      formData.delete('id_presales');
      formData.delete('id_development');



      let id_marketing = [];
      this.marketings.forEach((item, index) => {
        id_marketing.push(item.value)
      });

      let id_presales = [];
      this.presales.forEach((item, index) => {
        id_presales.push(item.value)
      });
      let id_development = [];
      this.developments.forEach((item, index) => {
        id_development.push(item.value)
      });

      formData.append('id_marketing', id_marketing.join(','))
      formData.append('id_development', id_development.join(','))
      formData.append('id_presales', id_presales.join(','))

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'form_konsultasi_insert';
      } else {
        this.api = base_url + 'form_konsultasi_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            localStorage.setItem('currentData', JSON.stringify(response.data));
            goPage("editdiscussion");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
