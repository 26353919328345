<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <div class="d-flex justify-content-between ">
                    <div>
                        <pageaddheader :title="title" :title1="title1" />
                    </div>
                    <div>
                        <router-link to="marketings" class="text-danger">
                            <vue-feather type="x-circle" data-bs-toggle="tooltip" title="fe fe-x-circle"
                                style="width:32px!important; height: 32px!important;"></vue-feather>
                        </router-link>

                    </div>
                </div>
                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top">
                            <div class="search-set">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Search..." aria-controls="DataTables_Table_0"
                                                v-model="query" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="wordset">
                                <ul>
                                    <li>
                                        <div class="d-flex flex-column justify-content-center align-items-center ">
                                            <a href="javascript:void(0);" @click="exportExcel()"><img
                                                    src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                                            <span style="font-size: 10px; margin-top: -8px;">excel</span>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /Filter -->
                        <!-- <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- /Filter -->
                        <div class="table-responsive">
                            <a-table :columns="columns" :data-source="filteredData" @change="onChange">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'invoice_date'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.invoice_date }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'invoice_no'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.invoice_no }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'isdeposit'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.isdeposit }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'item_name'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.item_name }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'item_qty'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.item_qty }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'item_total'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ rupiah(record.item_total) }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'nama_kategori'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.nama_kategori }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'nama_marketing'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.nama_marketing }}</a>
                                        </div>
                                    </template>
                                    <template v-else-if="column.key === 'action'">
                                        <div class="d-flex align-items-center ">
                                            <a href="javascript:void(0);" @click=" viewInvoice(record.slug)"
                                                class="me-3">
                                                <vue-feather type="file-text"></vue-feather>
                                            </a>
                                        </div>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Delete Data</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        Are u sure want to delete this data?
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary"
                            @click="hideModal2('terminate')">cancel</button>
                        <button type="submit" class="btn  btn-danger">Delete</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom, rupiah, openInNewTab } from "@/assets/js/function";

export default {
    data() {
        return {
            filter: false,
            title: "Marketing history List",
            title1: "Manage history",
            items: [
                {
                    text: "Add New Marketing",
                    addname: "/addmarketing",
                },
            ],

            //data
            data: [],
            columns: [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no.toLowerCase();
                            b = b.no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Tanggal Invoice",
                    dataIndex: "invoice_date",
                    key: "invoice_date",
                    sorter: {
                        compare: (a, b) => {
                            a = a.invoice_date.toLowerCase();
                            b = b.invoice_date.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nomor Invoice",
                    dataIndex: "invoice_no",
                    key: "invoice_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.invoice_no.toLowerCase();
                            b = b.invoice_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Deposit",
                    dataIndex: "isdeposit",
                    key: "isdeposit",
                    sorter: {
                        compare: (a, b) => {
                            a = a.isdeposit.toLowerCase();
                            b = b.isdeposit.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nama item",
                    dataIndex: "item_name",
                    key: "item_name",
                    sorter: {
                        compare: (a, b) => {
                            a = a.item_name.toLowerCase();
                            b = b.item_name.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Item qty",
                    dataIndex: "item_qty",
                    key: "item_qty",
                    sorter: {
                        compare: (a, b) => {
                            a = a.item_qty.toLowerCase();
                            b = b.item_qty.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Item total",
                    dataIndex: "item_total",
                    key: "item_total",
                    sorter: {
                        compare: (a, b) => {
                            a = a.item_total.toLowerCase();
                            b = b.item_total.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },

                {
                    title: "Nama Kategori",
                    dataIndex: "nama_kategori",
                    key: "nama_kategori",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_kategori.toLowerCase();
                            b = b.nama_kategori.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nama Marketing",
                    dataIndex: "nama_marketing",
                    key: "nama_marketing",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_marketing.toLowerCase();
                            b = b.nama_marketing.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },



                {
                    title: "Action",
                    key: "action",
                    sorter: false,
                },
            ],

            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            nama_terminate: "",

            //filter
            query: "",
        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        this.index();
    },

    computed: {
        filteredData() {
            // Filter data based on selected jenis_kehadiran
            if (this.query == '') {
                return this.data; // If no filter selected, return all data
            }
            const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison

            return this.data.filter(item =>
                item.invoice_date.toLowerCase().includes(lowerCaseQuery) ||
                item.invoice_no.toLowerCase().includes(lowerCaseQuery) ||
                item.isdeposit.toLowerCase().includes(lowerCaseQuery) ||
                item.item_name.toLowerCase().includes(lowerCaseQuery) ||
                item.item_qty.toLowerCase().includes(lowerCaseQuery) ||
                item.item_total.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_kategori.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_marketing.toLowerCase().includes(lowerCaseQuery)

            );
        }
    },
    methods: {
        rupiah,
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },
        viewInvoice(slug) {
            localStorage.setItem('slug', slug)
            openInNewTab('view');
        },
        index() {
            ShowLoading();
            let formData = new FormData();

            formData.append("id_marketing", JSON.parse(localStorage.getItem('currentData')).id);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'marketing_history';
            } else {
                this.api = base_url + 'marketing_history';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.data = [];

                        response.data.data.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });

                        this.title = "Marketing " + response.data.data[0].nama_marketing + " History List";



                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        exportExcel() {

            ShowLoading();
            let formData = new FormData();
            formData.append("id_marketing", JSON.parse(localStorage.getItem('currentData')).id);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'marketing_history_export';
            } else {
                this.api = base_url + 'marketing_history_export';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        openInNewTab(response.data.data.file_report);
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


    },
    name: "marketinghistory",
};
</script>
<style>
.ant-tooltip {
    display: none !important;
}
</style>