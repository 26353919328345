<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-3 col-sm-12 col-12">
                  
                  <div class="form-group">
                    <label>Fullname<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.fullname" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" v-model="currentData.email" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Phone<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.telepone" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Whatsapp<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.whatsapp" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date of Birth </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.dob" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Job Tittle</label>
                    <input type="text" v-model="currentData.job_title" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Company</label>
                    <input type="text" v-model="currentData.company" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Industry</label>
                    <input type="text" v-model="currentData.industry" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Interest</label>
                    <input type="text" v-model="currentData.interest" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Discount Rate (%)</label>
                    <input type="text" v-model="currentData.discount_rate" />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Pricing Level<span class="text-danger">*</span></label>
                    <vue-select :options="pricingList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.rate" placeholder="None" required />
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Billing Address</label>
                    <input type="text" class="mb-1" v-model="currentData.billing_address1" />
                    <input type="text" class="mb-1" v-model="currentData.billing_address2" />
                    <input type="text" class="mb-1" v-model="currentData.billing_address3" />
                  </div>


                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Shipping Address - [<a href="javascript:void(0);" @click="copyFromBillingAddres()">Copy from
                        billing
                        address</a>]</label>

                    <input type="text" class="mb-1" v-model="currentData.shipping_address1" />
                    <input type="text" class="mb-1" v-model="currentData.shipping_address2" />
                    <input type="text" class="mb-1" v-model="currentData.shipping_address3" />
                  </div>

                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Insert</button>
                  <router-link to="customers" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editcustomer",
  data() {
    return {
      filter: true,
      title: "Insert Customer",
      title1: "add new Customer",
      config: "",
      api: "",

      //data
      currentData: {
        fullname: "",
        email: "",
        telepone: "",
        whatsapp: "",
        job_title: "",
        dob: "",
        rate: 0,
        discount_rate: 0,
        company: "",
        industry: "",
        interest: "",
        billing_address1: "",
        billing_address2: "",
        billing_address3: "",
        shipping_address1: "",
        shipping_address2: "",
        shipping_address3: "",
        shipping_name: "",
        default_terms: 0,
        callback: "customers",

      },
      pricingList: [
        {
          "id": "0",
          "text": "Regular"
        },
        {
          "id": "2",
          "text": "Silver"
        },
        {
          "id": "3",
          "text": "Gold"
        },
        {
          "id": "4",
          "text": "Bronze"
        },
        {
          "id": "5",
          "text": "Platinum"
        },
      ],
      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.currentData.dob = new Date();
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }
    if (localStorage.getItem('callback') != null) {
      this.callback = localStorage.getItem('callback');
    }


  },

  mounted() {
    if (localStorage.getItem('callback') != null) {
      localStorage.removeItem('callback');
    }
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    copyFromBillingAddres() {

      this.currentData.shipping_address1 = this.currentData.billing_address1;
      this.currentData.shipping_address2 = this.currentData.billing_address2;
      this.currentData.shipping_address3 = this.currentData.billing_address3;
    },
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("dob");

      if (this.currentData.dob != 'Invalid Date' && this.currentData.dob != new Date()) {
        var formattedDate = this.currentData.dob.toISOString().split('T')[0];
        formData.append("dob", formattedDate);
      } else {
        formData.append("dob", null);
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'customer_insert';
      } else {
        this.api = base_url + 'customer_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);

            if (this.callback != 'customers') {
              localStorage.removeItem('callback');
              const object_data = { id: response.data.data, nama: this.currentData.fullname, from: 'customer' };
              localStorage.setItem('pass_data', JSON.stringify(object_data));
            }
            goPage(this.callback);
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
