<template>
    <form @submit.prevent="addpembayaran">
        <div class="modal fade" id="modalpayment" tabindex="-1" aria-labelledby="modalpayment" aria-hidden="true">

            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title">{{ title }}
                            </h5>
                            <span for=""> {{ sub }} </span>
                        </div>

                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                            ref="buttonclosemodalpayment">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- <div class="page-header">
                            <div class="page-title">
                                <h4>Categories</h4>
                                <h6>Manage your purchases</h6>
                            </div>
                        </div> -->


                        <div class="row">
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Notes </label>
                                        <input type="text" class="form-control form-control-sm"
                                            aria-controls="DataTables_Table_0" v-model="currentData.payment_notes"
                                            required />
                                    </div>
                                </div>
                            </div>
                            <div class=" col-12">
                                <div class="form-group">
                                    <label>Tanggal Pembayaran</label>
                                    <div class="form-group " style="margin-bottom: 0px!important; ">
                                        <!-- <label>Choose Company<span class="text-danger">*</span></label> -->
                                        <div class="input-groupicon" style="z-index: 1000;">
                                            <datepicker class="picker" v-model="currentData.payment_date"
                                                :editable="true" :clearable="false" required />
                                            <a class="addonset">
                                                <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">

                                        <label for="" v-if="currentData.status == 'tambah'">Billed ({{ 'Max : ' +
        rupiah(batas_payment) }})</label>
                                        <label for="" v-else>Billed ({{ 'Max : ' + rupiah(batas_payment_edit)
                                            }})</label>
                                        <input type="text" class="form-control form-control-sm"
                                            aria-controls="DataTables_Table_0" v-model="currentData.payment_amount"
                                            @input="formatCurrency(currentData.payment_amount)" required />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="payment_method">Select Payment Method</label>
                                        <model-select :options="paymentdata" placeholder="select payment method"
                                            v-model="currentData.payment_method" :isError="paymentCek"
                                            name="payment_method">
                                        </model-select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12">
                            <button class="btn btn-submit me-2" type="submit">Save</button>
                            <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<style></style>

<script>
import Cookies from "js-cookie";
import { ModelSelect } from 'vue-search-select';




import { AlertPopup, rupiah, rupiahoutput, rupiahInput, } from "@/assets/js/function";


export default {
    data() {
        return {
            //title
            title: "",
            sub: "",


            data: "",
            config: "",
            token: "",

            //add payment
            paymentCek: false,

            status: "",
            id: "",



            //sent add item 

        }

    },

    props: {
        paymentdata: Object,
        currentData: Object,
        batas_payment: Number,
        batas_payment_edit: Number,

    },
    watch: {
        'currentData.payment_method': {
            handler(newVal, oldValue) {



                this.paymentCek = false;
            },
        },
        'currentData': {
            handler(newVal) {

                if (newVal) {

                    if (newVal.payment_method != "") {
                        this.title = "Update Payment";
                        this.sub = "Update payment data";

                    } else {
                        this.title = "Add Payment";
                        this.sub = "Add payment data";

                    }



                    // if (newVal.payment_date == '') {
                    //     this.currentData.payment_date = new Date();
                    // } else {
                    //     this.currentData.payment_date = new Date(newVal.payment_date);
                    // }
                    // this.currentData.payment_amount = rupiahInput(parseInt(newVal.payment_amount));

                }
            },
        },
        'batas_payment': {
            handler(newVal) {
                // console.log(newVal);
            }
        },

    },

    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
    },


    methods: {
        rupiah,
        formatCurrency(number) {
            this.currentData.payment_amount = rupiahInput(number);
        },
        convertDate(value) {
            const date = new Date(value);

            // Use built-in methods to get year, month, and day
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        addpembayaran() {


            const payment_amount = rupiahoutput(this.currentData.payment_amount);
            const newFormatDate = this.convertDate(this.currentData.payment_date);

            if (this.currentData.status == 'tambah' && payment_amount > this.batas_payment) {
                AlertPopup('error', '', 'Amount paid exceeds invoice value', 3000, true);
                return true;
            } else if (this.currentData.status == 'update' && payment_amount > this.batas_payment_edit) {
                AlertPopup('error', '', 'Amount paid exceeds invoice value', 3000, true);
                return true;
            }

            const newObject = {
                id: this.currentData.id,
                status: this.currentData.status,
                payment_amount: payment_amount,
                payment_method: this.currentData.payment_method,
                payment_notes: this.currentData.payment_notes,
                payment_date: newFormatDate,
            }


            if (this.currentData.payment_method === '') {
                this.paymentCek = true;
                AlertPopup('error', '', 'Some fields are empty', 3000, true)
                return true;
            }
            if (payment_amount <= 0) {
                AlertPopup('error', '', 'Incorrect payment amount', 3000, true)
                return true;
            }

            this.$emit('pembayaran_object', newObject)

            const openButton = this.$refs.buttonclosemodalpayment;
            openButton.click();

        },



    },
    components: {

        ModelSelect

    },
}
</script>