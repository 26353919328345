import { createRouter, createWebHistory } from "vue-router";

import Index from "../views/pages/dashboard/index";
import Signin from "../views/pages/auth/signin";
import Pos from "../views/pages/pos/pos";
import Marketing from "../views/pages/marketing/marketing";
import Addmarketing from "../views/pages/marketing/addmarketing";
import Editmarketing from "../views/pages/marketing/editmarketing";
import Customer from "../views/pages/customer/customer";
import Addcustomer from "../views/pages/customer/addcustomer";
import Editcustomer from "../views/pages/customer/editcustomer";
import Customerhistory from "@/views/pages/customer/customerhistory.vue";
import Category from "../views/pages/category/category";
import Addcategory from "../views/pages/category/addcategory";
import Editcategory from "../views/pages/category/editcategory";
import Invoice from "../views/pages/invoice/invoice";
import Addinvoice from "@/views/pages/invoice/addinvoice.vue";
import Editinvoice from "../views/pages/invoice/editinvoice";
import viewInvoice from "../views/pages/pos/viewInvoice.vue";
import feedback from "../views/pages/pos/feedback.vue";
import Profile from "../views/pages/profile";
import Product from "../views/pages/product/product";
import Addproduct from "../views/pages/product/addproduct";
import Editproduct from "../views/pages/product/editproduct";
import Customergroup from "../views/pages/customergroup/customergroup";
import Addcustomergroup from "../views/pages/customergroup/addcustomergroup";
import Editcustomergroup from "../views/pages/customergroup/editcustomergroup";
import Campaign from "../views/pages/campaign/campaign";
import Addcampaign from "../views/pages/campaign/addcampaign";
import Editcampaign from "../views/pages/campaign/editcampaign";
import marketinghistory from "@/views/pages/marketing/marketinghistory";
import Quotation from "../views/pages/quotations/quotation";
import Addquotation from "@/views/pages/quotations/addquotation";
import Editquotation from "../views/pages/quotations/editquotation";
import viewquotation from "@/views/pages/quotations/viewquotation";
import Bank from "../views/pages/banks/bank";
import Addbank from "@/views/pages/banks/addbank";
import Editbank from "../views/pages/banks/editbank";
import Payment from "../views/pages/payments/payment";
import Addpayment from "@/views/pages/payments/addpayment";
import Editpayment from "../views/pages/payments/editpayment";
import Discussion from "../views/pages/discussion/discussion";
import Adddiscussion from "@/views/pages/discussion/adddiscussion";
import Editdiscussion from "../views/pages/discussion/editdiscussion";
import Confirmotp from "../views/pages/auth/confirmotp";
import Changepassword from "@/views/pages/auth/changepassword.vue";
import settingsprofilecompany from "@/views/pages/settingsprofilecompany.vue";
import Purchaseorder from "../views/pages/purchaseorder/purchaseorder";
import Addpurchaseorder from "@/views/pages/purchaseorder/addpurchaseorder";
import Editpurchaseorder from "../views/pages/purchaseorder/editpurchaseorder";
import viewpurchaseorder from "@/views/pages/purchaseorder/viewpurchaseorder";
import Purchaseinvoice from "../views/pages/purchaseinvoice/purchaseinvoice";
import Addpurchaseinvoice from "@/views/pages/purchaseinvoice/addpurchaseinvoice";
import Editpurchaseinvoice from "../views/pages/purchaseinvoice/editpurchaseinvoice";
import viewpurchaseinvoice from "@/views/pages/purchaseinvoice/viewpurchaseinvoice";

import Pettycashin from "../views/pages/pettycashin/pettycashin";
import Addpettycashin from "@/views/pages/pettycashin/addpettycashin";
import Editpettycashin from "../views/pages/pettycashin/editpettycashin";
import viewpettycashin from "@/views/pages/pettycashin/viewpettycashin";

import Pettycashout from "../views/pages/pettycashout/pettycashout";
import Addpettycashout from "@/views/pages/pettycashout/addpettycashout";
import Editpettycashout from "../views/pages/pettycashout/editpettycashout";
import viewpettycashout from "@/views/pages/pettycashout/viewpettycashout";

// new menu
import Template from "@/views/pages/template/template.vue";
import AddTemplate from "@/views/pages/template/addtemplate.vue";
import EditTemplate from "@/views/pages/template/edittemplate.vue";
import CustomerOpti from '@/views/pages/customeropti/customeropti.vue';
import AddCustomerOpti from '@/views/pages/customeropti/addcustomeropti.vue';
import EditCustomerOpti from '@/views/pages/customeropti/editcustomeropti.vue';


// import axios from "axios";

// import pin from "../views/pages/auth/pin";
// import resetPin from "../views/pages/auth/resetpin";

// import Confirmpin from "../views/pages/auth/confirmpin";
// import employee from "../views/pages/employee/employee";
// import Editemployee from "../views/pages/employee/editemployee";
// import Addemployee from "../views/pages/employee/addemployee";
// import Notif from "../views/pages/notification/notif";
// import Location from "../views/pages/location/location";
// import Addlocation from "../views/pages/location/addlocation";
// import Editlocation from "../views/pages/location/editlocation";
// import Connectedapps from "../views/pages/connected-apps/connectedapps";
// import Editconnectedapps from "../views/pages/connected-apps/editconnectedapps";

// import Admin from "../views/pages/management/admin/admin";
// import EditAdmin from "../views/pages/management/admin/editadmin";
// import AddAdmin from "../views/pages/management/admin/addadmin";
// import Shift from "../views/pages/management/shift/shift";
// import EditShift from "../views/pages/management/shift/editshift";
// import AddShift from "../views/pages/management/shift/addshift";
// import Jobtittle from "../views/pages/jobtittle/jobtittle";
// import Addjobtittle from "../views/pages/jobtittle/addjobtittle";
// import Editjobtittle from "../views/pages/jobtittle/editjobtittle";

// import Settingsprofile from "../views/pages/settingsprofile";
// import Historyattendance from "../views/pages/employee/historyattendance";
// import Addattendance from "../views/pages/employee/addattendance";
// import Editattendance from "../views/pages/employee/editattendance";
// import Importattendance from "../views/pages/employee/importattendance";
// import Birthday from "../views/pages/employee/birthday";
// import Hrsummaryreport from "../views/pages/reportemployee/hrsummaryreport";
// import Payrollreport from "../views/pages/reportemployee/payrollreport";
// import Payrollreportsalaryslip from "../views/pages/reportemployee/payrollreportsalaryslip";
// import Hrdailyreport from "../views/pages/reportemployee/hrdailyreport";
// import Approval from "../views/pages/notification/approval";

// import Activities from "../views/pages/activite/activities";
// import Addbrand from "../views/pages/product/addbrand";
// import Addcategory from "../views/pages/product/addcategory";
// import Addproduct from "../views/pages/product/addproduct";
// import Brandlist from "../views/pages/product/brandlist";
// import Categorylist from "../views/pages/product/categorylist";
// import Editbrand from "../views/pages/product/editbrand";
// import Editcategory from "../views/pages/product/editcategory";
// import Editproduct from "../views/pages/product/editproduct";
// import Editsubcategory from "../views/pages/product/editsubcategory";
// import Importproduct from "../views/pages/product/importproduct";
// import ProductDetails from "../views/pages/product/product-details";
// import Productlist from "../views/pages/product/productlist";
// import Subaddcategory from "../views/pages/product/subaddcategory";
// import Subcategorylist from "../views/pages/product/subcategorylist";
// import Addsale from "../views/pages/sales/add-sales";
// import createsalesreturns from "../views/pages/sales/createsalesreturns";
// import Editsales from "../views/pages/sales/edit-sales";
// import Editsalesreturns from "../views/pages/sales/editsalesreturns";
// import Salesdetails from "../views/pages/sales/sales-details";
// import Saleslist from "../views/pages/sales/saleslist";
// import Addpurchase from "../views/pages/purchase/addpurchase";
// import Editpurchase from "../views/pages/purchase/editpurchase";
// import Importpurchase from "../views/pages/purchase/importpurchase";
// import Purchaselist from "../views/pages/purchase/purchaselist";

// import Index2 from "../views/pages/dashboard/index2";
// import IndexOne from "../views/pages/dashboard/index-one";
// import IndexTwo from "../views/pages/dashboard/index-two";
// import IndexThree from "../views/pages/dashboard/index-three";
// import IndexFour from "../views/pages/dashboard/index-four";
// import createexpense from "../views/pages/expense/createexpense";
// import Editexpense from "../views/pages/expense/editexpense";
// import Expensecategory from "../views/pages/expense/expensecategory";
// import Expenselist from "../views/pages/expense/expenselist";
// import Addquotation from "../views/pages/quotation/addquotation";
// import Editquotation from "../views/pages/quotation/editquotation";
// import Quotationlist from "../views/pages/quotation/quotationlist";
// import Addtransfer from "../views/pages/transfer/addtransfer";
// import Edittransfer from "../views/pages/transfer/edittransfer";
// import Importtransfer from "../views/pages/transfer/importtransfer";
// import transferlist from "../views/pages/transfer/transferlist";
// import createpurchasereturn from "../views/pages/return/createpurchasereturn";
// import createsalesreturn from "../views/pages/return/createsalesreturn";
// import Editpurchasereturn from "../views/pages/return/editpurchasereturn";
// import Editsalesreturn from "../views/pages/return/editsalesreturn";
// import Purchasereturnlist from "../views/pages/return/purchasereturnlist";
// import Salesreturnlist from "../views/pages/return/salesreturnlist";
// import Salesreturnlists from "../views/pages/return/salesreturnlists";
// // import Addcustomer from "../views/pages/people/addcustomer";
// import Addstore from "../views/pages/people/addstore";
// import Addsupplier from "../views/pages/people/addsupplier";
// import Adduser from "../views/pages/people/adduser";
// // import Editcustomer from "../views/pages/people/editcustomer";
// import Editstore from "../views/pages/people/editstore";
// import Editsupplier from "../views/pages/people/editsupplier";
// import Edituser from "../views/pages/people/edituser";
// import Supplierlist from "../views/pages/people/supplierlist";
// import userlist from "../views/pages/people/userlist";
// import customerlist from "../views/pages/people/customer/customerlist";
// import Editcountry from "../views/pages/places/editcountry";
// import Editstate from "../views/pages/places/editstate";
// import Newcountry from "../views/pages/places/newcountry";
// import Newstate from "../views/pages/places/newstate";
// import Statelist from "../views/pages/places/statelist";
// import Storelist from "../views/pages/places/storelist";
// import Countrieslist from "../views/pages/places/countrylist/countrieslist";
// import Blankpage from "../views/pages/blankpage";

// import Component from "../views/pages/components/components";
// import Error404 from "../views/pages/error/error-404";
// import Error500 from "../views/pages/error/error-500";
// import clipboard from "../views/pages/elements/clipboard";
// import counter from "../views/pages/elements/counter";
// import Dragdrop from "../views/pages/elements/drag-drop";
// import Lightbox from "../views/pages/elements/lightbox";
// import notification from "../views/pages/elements/notification";
// import Barcode from "../views/pages/product/barcodeproduct/barcode";
// import Popover from "../views/pages/elements/popover";
// import Rangeslider from "../views/pages/elements/rangeslider";
// import Rating from "../views/pages/elements/rating";
// import Ribbon from "../views/pages/elements/ribbon";
// import Scrollbar from "../views/pages/elements/scrollbar";
// import Spinner from "../views/pages/elements/spinner";
// import Stickynote from "../views/pages/elements/stickynote";
// import Sweetalerts from "../views/pages/elements/sweetalerts";
// import Texteditor from "../views/pages/elements/text-editor";
// import timeline from "../views/pages/elements/timeline";
// import toastr from "../views/pages/elements/toastr";
// import tooltip from "../views/pages/elements/tooltip";
// import Chartapex from "../views/pages/charts/apex/chart-apex";
// import Chartc3 from "../views/pages/charts/c3/chart-c3";
// import chartflot from "../views/pages/charts/flot/chart-flot";
// import chartmorris from "../views/pages/charts/morris/chart-morris";
// import Iconfeather from "../views/pages/icons/icon-feather";
// import Iconflag from "../views/pages/icons/icon-flag";
// import Iconfontawesome from "../views/pages/icons/icon-fontawesome";
// import Iconionic from "../views/pages/icons/icon-ionic";
// import Iconpe7 from "../views/pages/icons/icon-pe7";
// import Iconsimpleline from "../views/pages/icons/icon-simpleline";
// import Iconthemify from "../views/pages/icons/icon-themify";
// import Icontypicon from "../views/pages/icons/icon-typicon";
// import Iconweather from "../views/pages/icons/icon-weather";
// import iconmaterial from "../views/pages/icons/icon-material";
// import Formbasicinputs from "../views/pages/forms/form-basic-inputs";
// import Formfileupload from "../views/pages/forms/form-fileupload";
// import Formhorizontal from "../views/pages/forms/horizontal/form-horizontal";
// import Forminputgroups from "../views/pages/forms/form-input-groups";
// import Formmask from "../views/pages/forms/form-mask";
// import Formselect2 from "../views/pages/forms/form-select2";
// import Formvalidation from "../views/pages/forms/form-validation";
// import Formvertical from "../views/pages/forms/form-vertical";
// import FormWizard from "../views/pages/forms/form-wizard";
// import chartJS from "../views/pages/charts/js/chart-js";
// import Datatable from "../views/pages/table/data-tables";
// import Tablesbasic from "../views/pages/table/tables-basic";
// import calendar from "../views/pages/application/calendar/calendar";
// import chat from "../views/pages/application/chat";
// import Email from "../views/pages/application/email";
// import customerreport from "../views/pages/report/customerreport";
// import Inventoryreport from "../views/pages/report/inventoryreport";
// import Invoicereport from "../views/pages/report/invoicereport";
// import Purchaseorderreport from "../views/pages/report/purchaseorderreport";
// import Purchasereport from "../views/pages/report/purchasereport";
// import Salesreport from "../views/pages/report/salesreport";
// import Supplierreport from "../views/pages/report/supplierreport/supplierreport";
// import Newuser from "../views/pages/users/newuser";
// import Newuseredit from "../views/pages/users/newuseredit";
// import userlists from "../views/pages/users/userlists";
// import currencysettings from "../views/pages/settings/currencysetting/currencysettings";
// import createpermission from "../views/pages/settings/createpermission";
// import Editpermission from "../views/pages/settings/editpermission";
// import Emailsettings from "../views/pages/settings/emailsettings";
// import Generalsettings from "../views/pages/settings/generalsettings";
// import Grouppermissions from "../views/pages/settings/grouppermissions";
// import Paymentsettings from "../views/pages/settings/paymentsettings";
// import Taxrates from "../views/pages/settings/taxrates";

import Signup from "../views/pages/auth/signup";


import Cookies from "js-cookie";




const routes = [
  {
    path: "/",
    name: "signin",
    component: Signin,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/confirmotp",
    name: "confirmotp",
    component: Confirmotp,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: Changepassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/index",
    name: "index",
    component: Index,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/pos",
    name: "pos",
    component: Pos,
    meta: {
      requiresAuth: true,


    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/settingsprofile",
    name: "settingsprofile",
    component: settingsprofilecompany,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },

  {
    path: "/marketings",
    name: "marketings",
    component: Marketing,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addmarketing",
    name: "addmarketing",
    component: Addmarketing,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editmarketing",
    name: "editmarketing",
    component: Editmarketing,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/marketinghistory",
    name: "marketinghistory",
    component: marketinghistory,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },

  {
    path: "/customers",
    name: "customers",
    component: Customer,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addcustomer",
    name: "addcustomer",
    component: Addcustomer,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editcustomer",
    name: "editcustomer",
    component: Editcustomer,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/customerhistory",
    name: "customerhistory",
    component: Customerhistory,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },

  // customer opti ======
  {
    path: "/customer_opti",
    name: "customeropti",
    component: CustomerOpti,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addcustomer_opti",
    name: "addcustomeropti",
    component: AddCustomerOpti,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editcustomer_opti",
    name: "editcustomeropti",
    component: EditCustomerOpti,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },

  // end =====================
  {
    path: "/view",
    name: "view",
    component: viewInvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,


    },
  },

  {
    path: "/feedback",
    name: "feedback",
    component: feedback,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,


    },
  },
  {
    path: "/kategori",
    name: "kategori",
    component: Category,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addkategori",
    name: "addkategori",
    component: Addcategory,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/editkategori",
    name: "editkategori",
    component: Editcategory,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },

  {
    path: "/invoices",
    name: "invoices",
    component: Invoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addinvoice",
    name: "addinvoice",
    component: Addinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editinvoice",
    name: "editinvoice",
    component: Editinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },

  {
    path: "/products",
    name: "products",
    component: Product,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addproduct",
    name: "addproduct",
    component: Addproduct,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editproduct",
    name: "editproduct",
    component: Editproduct,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/customergroup",
    name: "customergroup",
    component: Customergroup,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addcustomergroup",
    name: "addcustomergroup",
    component: Addcustomergroup,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editcustomergroup",
    name: "editcustomergroup",
    component: Editcustomergroup,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/campaign",
    name: "campaign",
    component: Campaign,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addcampaign",
    name: "addcampaign",
    component: Addcampaign,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editcampaign",
    name: "editcampaign",
    component: Editcampaign,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },


  },

  // new crm submenu "template"

  {
    path: "/template",
    name: "template",
    component: Template,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addtemplate",
    name: "addtemplate",
    component: AddTemplate,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/edittemplate",
    name: "edittemplate",
    component: EditTemplate,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },

  // end ==========================


  {
    path: "/quotations",
    name: "quotations",
    component: Quotation,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,

    },
  },
  {
    path: "/addquotation",
    name: "addquotation",
    component: Addquotation,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editquotation",
    name: "editquotation",
    component: Editquotation,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/viewquotation",
    name: "viewquotation",
    component: viewquotation,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,

    },
  },

  {
    path: "/banks",
    name: "banks",
    component: Bank,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addbank",
    name: "addbank",
    component: Addbank,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editbank",
    name: "editbank",
    component: Editbank,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/payments",
    name: "payments",
    component: Payment,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addpayment",
    name: "addpayment",
    component: Addpayment,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editpayment",
    name: "editpayment",
    component: Editpayment,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/discussions",
    name: "discussions",
    component: Discussion,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,



    },
  },
  {
    path: "/adddiscussion",
    name: "adddiscussion",
    component: Adddiscussion,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editdiscussion",
    name: "editdiscussion",
    component: Editdiscussion,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },

  {
    path: "/purchaseorders",
    name: "purchaseorders",
    component: Purchaseorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,

    },
  },
  {
    path: "/addpurchaseorder",
    name: "addpurchaseorder",
    component: Addpurchaseorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editpurchaseorder",
    name: "editpurchaseorder",
    component: Editpurchaseorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/viewpurchaseorder",
    name: "viewpurchaseorder",
    component: viewpurchaseorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,

    },
  },

  {
    path: "/purchaseinvoices",
    name: "purchaseinvoices",
    component: Purchaseinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,

    },
  },
  {
    path: "/addpurchaseinvoice",
    name: "addpurchaseinvoice",
    component: Addpurchaseinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editpurchaseinvoice",
    name: "editpurchaseinvoice",
    component: Editpurchaseinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/viewpurchaseinvoice",
    name: "viewpurchaseinvoice",
    component: viewpurchaseinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,

    },
  },


  {
    path: "/pettycashins",
    name: "pettycashins",
    component: Pettycashin,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,

    },
  },
  {
    path: "/addpettycashin",
    name: "addpettycashin",
    component: Addpettycashin,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editpettycashin",
    name: "editpettycashin",
    component: Editpettycashin,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/viewpettycashin",
    name: "viewpettycashin",
    component: viewpettycashin,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,

    },
  },


  {
    path: "/pettycashouts",
    name: "pettycashouts",
    component: Pettycashout,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,

    },
  },
  {
    path: "/addpettycashout",
    name: "addpettycashout",
    component: Addpettycashout,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editpettycashout",
    name: "editpettycashout",
    component: Editpettycashout,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/viewpettycashout",
    name: "viewpettycashout",
    component: viewpettycashout,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,

    },
  },


  // {
  //   path: "/pin",
  //   name: "pin",
  //   component: pin,
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  // {
  //   path: "/confirm-otp",
  //   name: "confirm-otp",
  //   component: Confirmotp,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reset-pin",
  //   name: "reset-pin",
  //   component: resetPin,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/confirm-pin",
  //   name: "confirmpin",
  //   component: Confirmpin,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/signup",
  //   name: "signup",
  //   component: Signup,
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },

  // {
  //   path: "/employee",
  //   name: "employee",
  //   component: employee,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/editemployee",
  //   name: "editemployee",
  //   component: Editemployee,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/addemployee",
  //   name: "addemployee",
  //   component: Addemployee,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/notif",
  //   name: "notif",
  //   component: Notif,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },

  // {
  //   path: "/location",
  //   name: "location",
  //   component: Location,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/addlocation",
  //   name: "addlocation",
  //   component: Addlocation,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/editlocation",
  //   name: "editlocation",
  //   component: Editlocation,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/connectedapps",
  //   name: "connectedapps",
  //   component: Connectedapps,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/editconnectedapps",
  //   name: "editconnectedapps",
  //   component: Editconnectedapps,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },

  // {
  //   path: "/admin",
  //   name: "admin",
  //   component: Admin,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/addadmin",
  //   name: "addadmin",
  //   component: AddAdmin,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/editadmin",
  //   name: "editadmin",
  //   component: EditAdmin,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/shift",
  //   name: "shift",
  //   component: Shift,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/addshift",
  //   name: "addshift",
  //   component: AddShift,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/editshift",
  //   name: "editshift",
  //   component: EditShift,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },

  // {
  //   path: "/departement",
  //   name: "departement",
  //   component: Departement,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/adddepartement",
  //   name: "adddepartement",
  //   component: Adddepartement,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/editdepartement",
  //   name: "editdepartement",
  //   component: Editdepartement,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/settingsprofile",
  //   name: "settingsprofile",
  //   component: Settingsprofile,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/historyattendance",
  //   name: "historyattendance",
  //   component: Historyattendance,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/importattendance",
  //   name: "importattendance",
  //   component: Importattendance,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/addattendance",
  //   name: "addattendance",
  //   component: Addattendance,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/editattendance",
  //   name: "editattendance",
  //   component: Editattendance,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/birthday",
  //   name: "birthday",
  //   component: Birthday,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/hrsummaryreport",
  //   name: "hrsummaryreport",
  //   component: Hrsummaryreport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/payrollreport",
  //   name: "payrollreport",
  //   component: Payrollreport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/payrollreportsalaryslip",
  //   name: "payrollreportsalaryslip",
  //   component: Payrollreportsalaryslip,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/hrdailyreport",
  //   name: "hrdailyreport",
  //   component: Hrdailyreport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/approval",
  //   name: "approval",
  //   component: Approval,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },

  // {
  //   path: "/index2",
  //   name: "index2",
  //   component: Index2,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/index-one",
  //   name: "index-one",
  //   component: IndexOne,
  //   meta: {
  //     headerClass: "header header-one",
  //     headerthreeClass: "sidebar new-header sidebar-one",
  //     sidebarClass: "sidebar-one hide-sidebar",
  //   },
  // },
  // {
  //   path: "/index-two",
  //   name: "index-two",
  //   component: IndexTwo,
  //   meta: {
  //     headerClass: "header header-two",
  //     divClass: "container",
  //   },
  // },
  // {
  //   path: "/index-three",
  //   name: "index-three",
  //   component: IndexThree,
  //   meta: {
  //     headerClass: "header header-three",
  //     headerthreeClass: "sidebar side-three new-header",
  //     divClass: "container",
  //     sidebarClass: "sidebar-three hide-sidebar",
  //   },
  // },
  // {
  //   path: "/index-four",
  //   name: "index-four",
  //   component: IndexFour,
  //   meta: {
  //     headerClass: "header header-four",
  //     hideClass: "v-cloak",
  //   },
  // },
  // {
  //   path: "/activities",
  //   name: "activities",
  //   component: Activities,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addbrand",
  //   name: "addbrand",
  //   component: Addbrand,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addcategory",
  //   name: "addcategory",
  //   component: Addcategory,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addproduct",
  //   name: "addproduct",
  //   component: Addproduct,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/brandlist",
  //   name: "brandlist",
  //   component: Brandlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/categorylist",
  //   name: "categorylist",
  //   component: Categorylist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editbrand",
  //   name: "editbrand",
  //   component: Editbrand,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editcategory",
  //   name: "editcategory",
  //   component: Editcategory,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editproduct",
  //   name: "editproduct",
  //   component: Editproduct,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editsubcategory",
  //   name: "editsubcategory",
  //   component: Editsubcategory,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/importproduct",
  //   name: "importproduct",
  //   component: Importproduct,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/product-details",
  //   name: "product-details",
  //   component: ProductDetails,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/productlist",
  //   name: "productlist",
  //   component: Productlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/subaddcategory",
  //   name: "subaddcategory",
  //   component: Subaddcategory,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/subcategorylist",
  //   name: "subcategorylist",
  //   component: Subcategorylist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/add-sales",
  //   name: "add-sales",
  //   component: Addsale,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/createsalesreturns",
  //   name: "createsalesreturns",
  //   component: createsalesreturns,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/edit-sales",
  //   name: "edit-sales",
  //   component: Editsales,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editsalesreturns",
  //   name: "editsalesreturns",
  //   component: Editsalesreturns,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/sales-details",
  //   name: "sales-details",
  //   component: Salesdetails,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/saleslist",
  //   name: "saleslist",
  //   component: Saleslist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addpurchase",
  //   name: "addpurchase",
  //   component: Addpurchase,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editpurchase",
  //   name: "editpurchase",
  //   component: Editpurchase,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/importpurchase",
  //   name: "importpurchase",
  //   component: Importpurchase,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/purchaselist",
  //   name: "purchaselist",
  //   component: Purchaselist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/createexpense",
  //   name: "createexpense",
  //   component: createexpense,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editexpense",
  //   name: "editexpense",
  //   component: Editexpense,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/expensecategory",
  //   name: "expensecategory",
  //   component: Expensecategory,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/expenselist",
  //   name: "expenselist",
  //   component: Expenselist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addquotation",
  //   name: "addquotation",
  //   component: Addquotation,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editquotation",
  //   name: "editquotation",
  //   component: Editquotation,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/quotationlist",
  //   name: "quotationlist",
  //   component: Quotationlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addtransfer",
  //   name: "addtransfer",
  //   component: Addtransfer,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/edittransfer",
  //   name: "edittransfer",
  //   component: Edittransfer,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/importtransfer",
  //   name: "importtransfer",
  //   component: Importtransfer,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/transferlist",
  //   name: "transferlist",
  //   component: transferlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/createpurchasereturn",
  //   name: "createpurchasereturn",
  //   component: createpurchasereturn,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/createsalesreturn",
  //   name: "createsalesreturn",
  //   component: createsalesreturn,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editpurchasereturn",
  //   name: "editpurchasereturn",
  //   component: Editpurchasereturn,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editsalesreturn",
  //   name: "editsalesreturn",
  //   component: Editsalesreturn,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/purchasereturnlist",
  //   name: "purchasereturnlist",
  //   component: Purchasereturnlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/salesreturnlist",
  //   name: "salesreturnlist",
  //   component: Salesreturnlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/salesreturnlists",
  //   name: "salesreturnlists",
  //   component: Salesreturnlists,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addcustomer",
  //   name: "addcustomer",
  //   component: Addcustomer,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addstore",
  //   name: "addstore",
  //   component: Addstore,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/addsupplier",
  //   name: "addsupplier",
  //   component: Addsupplier,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/adduser",
  //   name: "adduser",
  //   component: Adduser,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editcustomer",
  //   name: "editcustomer",
  //   component: Editcustomer,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editstore",
  //   name: "editstore",
  //   component: Editstore,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editsupplier",
  //   name: "editsupplier",
  //   component: Editsupplier,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/edituser",
  //   name: "edituser",
  //   component: Edituser,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/supplierlist",
  //   name: "supplierlist",
  //   component: Supplierlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/userlist",
  //   name: "userlist",
  //   component: userlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/customerlist",
  //   name: "customerlist",
  //   component: customerlist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editcountry",
  //   name: "editcountry",
  //   component: Editcountry,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editstate",
  //   name: "editstate",
  //   component: Editstate,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/newcountry",
  //   name: "newcountry",
  //   component: Newcountry,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/newstate",
  //   name: "newstate",
  //   component: Newstate,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/statelist",
  //   name: "statelist",
  //   component: Statelist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/storelist",
  //   name: "storelist",
  //   component: Storelist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/countrieslist",
  //   name: "countrieslist",
  //   component: Countrieslist,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/blankpage",
  //   name: "blankpage",
  //   component: Blankpage,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },

  // {
  //   path: "/components",
  //   name: "components",
  //   component: Component,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/error-404",
  //   name: "error-404",
  //   component: Error404,
  // },
  // {
  //   path: "/error-500",
  //   name: "error-500",
  //   component: Error500,
  // },
  // {
  //   path: "/clipboard",
  //   name: "clipboard",
  //   component: clipboard,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/counter",
  //   name: "counter",
  //   component: counter,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/drag-drop",
  //   name: "drag-drop",
  //   component: Dragdrop,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/lightbox",
  //   name: "lightbox",
  //   component: Lightbox,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/notification",
  //   name: "notification",
  //   component: notification,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/barcode",
  //   name: "barcode",
  //   component: Barcode,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/popover",
  //   name: "popover",
  //   component: Popover,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/rangeslider",
  //   name: "rangeslider",
  //   component: Rangeslider,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/rating",
  //   name: "rating",
  //   component: Rating,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/ribbon",
  //   name: "ribbon",
  //   component: Ribbon,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/scrollbar",
  //   name: "scrollbar",
  //   component: Scrollbar,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/spinner",
  //   name: "spinner",
  //   component: Spinner,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/stickynote",
  //   name: "stickynote",
  //   component: Stickynote,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/sweetalerts",
  //   name: "sweetalerts",
  //   component: Sweetalerts,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/text-editor",
  //   name: "text-editor",
  //   component: Texteditor,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/timeline",
  //   name: "timeline",
  //   component: timeline,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/toastr",
  //   name: "toastr",
  //   component: toastr,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/tooltip",
  //   name: "tooltip",
  //   component: tooltip,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/chart-apex",
  //   name: "chart-apex",
  //   component: Chartapex,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/chart-c3",
  //   name: "chart-c3",
  //   component: Chartc3,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/chart-flot",
  //   name: "chart-flot",
  //   component: chartflot,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/chart-morris",
  //   name: "chart-morris",
  //   component: chartmorris,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-feather",
  //   name: "icon-feather",
  //   component: Iconfeather,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-flag",
  //   name: "icon-flag",
  //   component: Iconflag,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-fontawesome",
  //   name: "icon-fontawesome",
  //   component: Iconfontawesome,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-ionic",
  //   name: "icon-ionic",
  //   component: Iconionic,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-pe7",
  //   name: "icon-pe7",
  //   component: Iconpe7,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-simpleline",
  //   name: "icon-simpleline",
  //   component: Iconsimpleline,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-themify",
  //   name: "icon-themify",
  //   component: Iconthemify,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-typicon",
  //   name: "icon-typicon",
  //   component: Icontypicon,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-weather",
  //   name: "icon-weather",
  //   component: Iconweather,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/icon-material",
  //   name: "icon-material",
  //   component: iconmaterial,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-basic-inputs",
  //   name: "form-basic-inputs",
  //   component: Formbasicinputs,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-fileupload",
  //   name: "form-fileupload",
  //   component: Formfileupload,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-horizontal",
  //   name: "form-horizontal",
  //   component: Formhorizontal,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-input-groups",
  //   name: "form-input-groups",
  //   component: Forminputgroups,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-mask",
  //   name: "form-mask",
  //   component: Formmask,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-select2",
  //   name: "form-select2",
  //   component: Formselect2,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-validation",
  //   name: "form-validation",
  //   component: Formvalidation,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-vertical",
  //   name: "form-vertical",
  //   component: Formvertical,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/form-wizard",
  //   name: "form-wizard",
  //   component: FormWizard,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/chart-js",
  //   name: "chart-js",
  //   component: chartJS,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/data-tables",
  //   name: "data-tables",
  //   component: Datatable,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/tables-basic",
  //   name: "tables-basic",
  //   component: Tablesbasic,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/calendar",
  //   name: "calendar",
  //   component: calendar,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/chat",
  //   name: "chat",
  //   component: chat,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/email",
  //   name: "email",
  //   component: Email,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/customerreport",
  //   name: "customerreport",
  //   component: customerreport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/inventoryreport",
  //   name: "inventoryreport",
  //   component: Inventoryreport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/invoicereport",
  //   name: "invoicereport",
  //   component: Invoicereport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/purchaseorderreport",
  //   name: "purchaseorderreport",
  //   component: Purchaseorderreport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/purchasereport",
  //   name: "purchasereport",
  //   component: Purchasereport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/salesreport",
  //   name: "salesreport",
  //   component: Salesreport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/supplierreport",
  //   name: "supplierreport",
  //   component: Supplierreport,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/newuser",
  //   name: "newuser",
  //   component: Newuser,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/newuseredit",
  //   name: "newuseredit",
  //   component: Newuseredit,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/userlists",
  //   name: "userlists",
  //   component: userlists,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/currencysettings",
  //   name: "currencysettings",
  //   component: currencysettings,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/createpermission",
  //   name: "createpermission",
  //   component: createpermission,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/editpermission",
  //   name: "editpermission",
  //   component: Editpermission,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/emailsettings",
  //   name: "emailsettings",
  //   component: Emailsettings,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/generalsettings",
  //   name: "generalsettings",
  //   component: Generalsettings,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/grouppermissions",
  //   name: "grouppermissions",
  //   component: Grouppermissions,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/paymentsettings",
  //   name: "paymentsettings",
  //   component: Paymentsettings,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/taxrates",
  //   name: "taxrates",
  //   component: Taxrates,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/forgetpassword",
  //   name: "forgetpassword",
  //   component: Forgetpassword,
  // },
  // {
  //   path: "/resetpassword",
  //   name: "resetpassword",
  //   component: Resetpassword,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //   },
  // },
  // {
  //   path: "/pos",
  //   name: "pos",
  //   component: Pos,
  //   meta: {
  //     headerClass: "header",
  //   },
  // },
];
export const router = createRouter({
  // history: createWebHistory("/level_up/"),
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  const auth_menu = JSON.parse(localStorage.getItem('auth_menu'));

  // console.log(auth_menu)
  // // URL of the text file
  // const url = "https://levelup.arthaskyline.com/versi.txt";

  // // Fetch the file content using Axios
  // axios
  //   .get(url)
  //   .then((response) => {
  //     // Assign the file content to the data property
  //     const fileContent = response.data;
  //     console.log(fileContent);
  //   })
  //   .catch((error) => {
  //     // Handle any errors
  //     console.error("There was a problem fetching the file:", error);
  //   });

  const token = Cookies.get("token_refresh");
  // kalo masuk yang harus pake token maka cek tokennya
  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    token != "" &&
    token != undefined
  ) {
    let routing_name = to.name.replace(/s$/, '');

    if (routing_name.includes('kategori')) {
      routing_name = routing_name.replace('kategori', 'category');
    }

    // if (routing_name.includes('customeropti')) {
    //   routing_name = routing_name.replace('customeropti', 'customeropti');
    // }



    if (routing_name == 'profile' || routing_name == 'settingsprofile') {
      next();
    } else if (routing_name == 'index') {
      next();
    } else if (routing_name.includes('add') && auth_menu.some(item => ((item.menu.toLowerCase()).replace('_', '')).includes(routing_name.replace('add', '')))) {
      const index = auth_menu.findIndex((item) => ((item.menu.toLowerCase()).replace('_', '')) == routing_name.replace('add', ''));

      if (index > -1) {
        if (auth_menu[index].create == 'AKTIF') {
          next();
        } else {

          next({ name: "index" });
        }
      } else {
        next({ name: "index" });
      }



    } else if (routing_name.includes('edit') && auth_menu.some(item => ((item.menu.toLowerCase()).replace('_', '')).includes(routing_name.replace('edit', '')))) {
      const index = auth_menu.findIndex((item) => ((item.menu.toLowerCase()).replace('_', '')) == routing_name.replace('edit', ''));

      if (routing_name == 'editdiscussion') {
        const routing_name_2 = routing_name + 'summary';


        const indexSubRoute = auth_menu.findIndex((item) => ((item.menu.toLowerCase()).replace('_', '')) == routing_name_2.replace('edit', ''));


        if (indexSubRoute > -1) {
          if (auth_menu[indexSubRoute].create == 'AKTIF' || auth_menu[indexSubRoute].update == 'AKTIF' || auth_menu[indexSubRoute].delete == 'AKTIF') {
            next();
          } else {

            next({ name: "index" });
          }
        } else {
          next({ name: "index" });
        }

      } else {
        if (index > -1) {
          if (auth_menu[index].update == 'AKTIF') {
            next();
          } else {

            next({ name: "index" });
          }
        } else {
          next({ name: "index" });
        }
      }

    } else if (auth_menu.some(item => ((item.menu.toLowerCase()).replace('_', '')).includes(routing_name))) {

      next();

    } else {
      next({ name: "index" });
    }





    //ini default 
    // next();
    // kalo masuk yang harus pake token tapi tokennya kosong maka balik lagi ke login
  } else if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    (token == "" || token == undefined)
  ) {
    next({ name: "signin" });
    // kalo gk pake token bisa langsung akses pagenya
  } else if (to.matched.some((route) => route.meta.requiresAuth == false)) {
    next();
    // kalo tidak matching dengan routing apapun langsung direct ke index
  } else {
    next({ name: "index" });
  }

  // if (
  //   !to.matched.some((route) => route.meta.requiresAuth) ||
  //   token == "" ||
  //   token == undefined
  // ) {
  //   CloseLoading();
  //   if (!to.matched.some((route) => route.meta.requiresAuth)) {
  //     next();
  //   } else {
  //     next({ name: "login" });
  //   }
  // } else {
  //   let formData = new FormData();
  //   const config = {
  //     headers: { Authorization: `Bearer ` + Cookies.get("token") },
  //     timeout: 0,
  //   };

  //   if (Cookies.get("token") == "") {
  //     CloseLoading();
  //     next();
  //   } else {
  //     const api = base_url + "profile_detail";
  //     axios
  //       .post(api, formData, config)
  //       .then((response) => {
  //         if (response.data.status == true) {
  //           let qr = response.data.status_qr;

  //           localStorage.setItem("tipe_user", response.data.tipe);
  //           localStorage.setItem("nama_user", response.data.data);
  //           localStorage.setItem(
  //             "menu",
  //             JSON.stringify(response.data.menu.map((obj) => obj.menu))
  //           );
  //           localStorage.setItem(
  //             "menu_akses",
  //             JSON.stringify(response.data.menu)
  //           );

  //           if (
  //             response.data.tipe == "dosen" ||
  //             response.data.tipe == "pegawai"
  //           ) {
  //             if (to.matched.some((route) => route.meta.requiresMenuAkses)) {
  //               if (
  //                 JSON.parse(localStorage.getItem("menu")).includes(to.name) ==
  //                 true
  //               ) {
  //                 if (to.fullPath == "/jadwal_mengajar" && qr == 1) {
  //                   next({ name: "jadwal_mengajar_qr" });
  //                 } else if (to.fullPath == "/jadwal_mengajar" && qr == 2) {
  //                   next({ name: "jadwal_mengajar_qr_2" });
  //                 } else {
  //                   next();
  //                 }
  //               } else {
  //                 if (
  //                   to.fullPath == "/berita_acara_dosen_tidak_hadir" &&
  //                   localStorage.getItem("menu").includes("laporan")
  //                 ) {
  //                   next();
  //                 } else if (to.fullPath == "/jadwal_mengajar_qr" && qr == 1) {
  //                   next({ name: "jadwal_mengajar_qr" });
  //                 } else if (to.fullPath == "/jadwal_mengajar_qr" && qr == 2) {
  //                   next({ name: "jadwal_mengajar_qr_2" });
  //                 } else {
  //                   next({ name: "Home" });
  //                 }
  //               }
  //             } else {
  //               if (to.fullPath == "/jadwal_mengajar_qr" && qr == 0) {
  //                 next({ name: "jadwal_mengajar" });
  //               } else {
  //                 next();
  //               }
  //             }
  //           } else {
  //             next();
  //           }
  //           CloseLoading();
  //         } else {
  //           if (response.data.message == "No Authorization") {
  //             next({ name: "login" });
  //           } else {
  //             AlertPopup("error", "", response.data.message, 1500, false);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         ErrorConnectionTimeOut(error);
  //       });
  //   }
  // }
});
