<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Lengkap <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.fullname" placeholder="" required />
                  </div>
                  <div class="form-group">
                    <label>Golongan <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.golongan" placeholder="" required />
                  </div>
                  <div class="form-group">
                    <label>Komisi <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.komisi" placeholder="" required />
                  </div>
                </div>

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Insert</button>
                  <router-link to="marketings" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editmarketing",
  data() {
    return {
      filter: true,
      title: "Insert Marketing",
      title1: "add new Marketing",
      config: "",
      api: "",
      callback: "marketings",

      //data
      currentData: {
      },
      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }
    if (localStorage.getItem('callback') != null) {
      this.callback = localStorage.getItem('callback');
    }


  },
  mounted() {
    localStorage.removeItem('callback');
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'marketing_insert';
      } else {
        this.api = base_url + 'marketing_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);


            if (this.callback != 'marketings') {
              localStorage.removeItem('callback');
              const object_data = { id: response.data.data, nama: this.currentData.fullname, from: 'marketing' };

              localStorage.setItem('pass_data', JSON.stringify(object_data));
            }
            goPage(this.callback);

            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },



  },

};
</script>
