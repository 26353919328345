<template>
  <!-- Logo -->
  <div class="header-left active">
    <a href="javascript:void(0);" @click="goToIndex()" class="logo logo-normal">
      <img src="../assets/img/logo.png" alt="" width="10" />
    </a>
    <a href="javascript:void(0);" @click="goToIndex()" class="logo logo-white">
      <img src="../assets/img/logo-white.png" alt="" />
    </a>
    <a href="javascript:void(0);" @click="goToIndex()" class="logo-small">
      <img src="../assets/img/logo-small.png" class="mb-2" alt="" style="width: 48px!important;" />
    </a>

  </div>
  <!-- /Logo -->
</template>

<script>
import { goPage } from "@/assets/js/function";
export default {
  methods: {
    goToIndex() {
      if (localStorage.getItem('tipe_user') != 'KAS') {
        goPage('index');
      } else {
        goPage('pos');
      }
    }
  }
}
</script>
